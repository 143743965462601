import {
    ActionButton,
    ComboBox,
    DefaultButton,
    Dropdown,
    IMessageBarStyles,
    ITextField,
    ITextFieldStyles,
    Icon,
    IconButton,
    Link,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    ProgressIndicator,
    Stack,
    Text,
    TextField,
    TooltipHost
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { FormEvent, createRef, useEffect, useState } from 'react';

import FeatureFlightService from '../../../services/featureFlight.service';
import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { flagNotValidatedMessage } from '../configs/defaults';
import { gapStackTokensMedium, gapStackTokensSmall } from '../styles/FFv2Style';
import { buttonStyles, pairInputStyle, removeIcon } from '../styles/StartFlightFormStyle';
import { FeatureFlags, FeatureFlagSchema, FlagPair, ValidateFeatureFlagResponse } from '../types/Types';
import { areObjectsEqual, flattenJSON, isValidJSON } from '../utilities/FFv2Utils';

type FlagsInputProps = {
    reset: boolean;
    showAllErrors: boolean;
    update: (flag?: string, config?: string) => void;
    validationDisabled?: boolean;
    workItemFeatureFlags?: string[];
    defaultFlags?: string;
    defaultControlConfigs?: string;
};

/**
 * Renders an input field for entering feature flags in JSON format and validates the input against the linked work item's feature flag list.
 *
 * @param props - The component's props.
 * @returns The JSX element representing the feature flags input field.
 */
const FlagsInput: React.FC<FlagsInputProps> = (props) => {
    // ========================= State =========================
    const { reset, showAllErrors, workItemFeatureFlags, validationDisabled } = props;
    const defaultFlags = props.defaultFlags ?? '';
    const defaultControlConfigs = props.defaultControlConfigs ?? '';
    const defaultValidationMessage = { type: 'none', message: '' };

    const [isJSONInput, setIsJSONInput] = useBoolean(false);
    const [flagSchemas, setFlagSchemas] = useState<Record<string, FeatureFlagSchema | null> | undefined>(undefined);

    const [flagsString, setFlagsString] = useState<string>(defaultFlags);
    const [controlString, setControlString] = useState<string>(defaultControlConfigs);
    const [flagPairs, setFlagPairs] = useState<FlagPair[]>(getDefaultFlagPairs());
    const [flagsErrorMessage, setFlagsErrorMessage] = useState<string>('');
    const [schemaErrorMessage, setSchemaErrorMessage] = useState<string>('');
    const [flagsWarningMessage, setFlagsWarningMessage] = useState<string>('');
    const [flagsValidationMessage, setFlagsValidationMessage] = useState<{ type: string; message: string }>(defaultValidationMessage);
    const textFieldRef = createRef<ITextField>();

    const flagNames = flagPairs.map((flag) => flag.name).filter((flag) => flag.length > 0);
    const flagOptions = workItemFeatureFlags
        ? flagPairs
              .map((flagPair) => flagPair.name)
              .filter((flagName) => !workItemFeatureFlags.includes(flagName) && flagName !== '')
              .concat(workItemFeatureFlags)
              .map((flagName) => ({
                  key: flagName,
                  text: flagName,
                  disabled: flagPairs.map((flag) => flag.name).includes(flagName)
              }))
        : [];

    // ========================= Hooks =========================

    useEffect(() => {
        if (!workItemFeatureFlags || workItemFeatureFlags.length === 0 || validationDisabled) return;
        fetchSchema(workItemFeatureFlags);
        if (defaultFlags.length > 0) {
            const defaultFlagNames = getDefaultFlagPairs()
                .map((item) => item.name)
                .filter((flag) => flag.length > 0);
            fetchSchema(defaultFlagNames);
        }
    }, [workItemFeatureFlags, validationDisabled]);

    useEffect(() => {
        setFlagsString(defaultFlags);
        setControlString(defaultControlConfigs);
        setFlagPairs(getDefaultFlagPairs());
        setFlagsErrorMessage('');
        setFlagsWarningMessage('');
        setFlagsValidationMessage(defaultValidationMessage);
    }, [reset, workItemFeatureFlags]);

    useEffect(() => {
        if (validationDisabled) setFlagsValidationMessage(defaultValidationMessage);
    }, [validationDisabled]);

    useEffect(() => {
        const { errorMsg } = getErrorMessage();
        setFlagsWarningMessage('');
        if (errorMsg === '' && !validationDisabled) {
            setFlagsErrorMessage(flagNotValidatedMessage);
        } else {
            setFlagsErrorMessage(errorMsg);
        }
        if (errorMsg.length > 0 || !validationDisabled) {
            setFlagsValidationMessage(defaultValidationMessage);
            props.update('{}');
        } else {
            const flags = isJSONInput ? flagsString : pairsToJSON(flagPairs);
            const controls = isJSONInput ? controlString : pairsToJSON(flagPairs.map((item) => ({ name: item.name, value: item.control })));
            props.update(flags, controls);
        }
    }, [flagsString, flagPairs, controlString, validationDisabled]);

    useEffect(() => {
        if (!showAllErrors) return;
        if (isJSONInput && textFieldRef.current) {
            textFieldRef.current.focus();
            textFieldRef.current.blur();
        } else {
            const { errorMsg } = getErrorMessage();
            setFlagsErrorMessage(errorMsg);
        }
    }, [showAllErrors]);

    useEffect(() => {
        if (!workItemFeatureFlags?.length) {
            setSchemaErrorMessage('');
            return;
        }
        const hasMissingSchemas = flagPairs.some((flag) => flagSchemas?.[flag.name] === undefined);
        setSchemaErrorMessage(hasMissingSchemas ? 'Please specify the flag in the format <category>.<flagName>' : '');
    }, [flagPairs, flagSchemas]);

    // ========================= Helper Function =========================

    const getErrorMessage = () => {
        let errorMsg = '';

        const flags = isJSONInput ? flagsString : pairsToJSON(flagPairs);
        const controls = isJSONInput ? controlString : pairsToJSON(flagPairs.map((item) => ({ name: item.name, value: item.control })));

        if (
            !flags ||
            flags === '{}' ||
            !controls ||
            controls === '{}' ||
            (!isJSONInput && flagPairs.some((item) => item.value === undefined || item.control === undefined))
        ) {
            errorMsg = 'Flags and control configs must not be empty';
        } else if (!isValidJSON(flags) || !isValidJSON(controls)) {
            errorMsg = 'Invalid JSON';
        } else {
            const flattenedFlags = flattenJSON(JSON.parse(flags));
            const flattenedControls = flattenJSON(JSON.parse(controls));
            if (JSON.stringify(Object.keys(flattenedFlags)) !== JSON.stringify(Object.keys(flattenedControls))) {
                errorMsg = 'Flags and control configs keys do not match';
            }
            if (areObjectsEqual(flattenedFlags, flattenedControls)) {
                errorMsg = 'Flags and control configs should not be the same';
            }
        }

        return { errorMsg, flags, controls };
    };

    // ========================= Event handlers =========================
    const handleFlagsJSONChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue !== undefined) {
            setFlagsString(newValue);
        }
    };

    const handleControlConfigJSONChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue !== undefined) {
            setControlString(newValue);
        }
    };

    const handleFlagPairChange = (index: number, name?: string, value?: string, isControlValue?: boolean) => {
        const updatedFlagPair = [...flagPairs];
        if (name !== flagPairs[index].name) {
            updatedFlagPair[index] = { name: name ?? '', value: undefined, control: undefined };
        } else {
            isControlValue ? (updatedFlagPair[index].control = value) : (updatedFlagPair[index].value = value);
        }
        setFlagPairs(updatedFlagPair);
    };

    const handleAddFlag = () => {
        setFlagPairs((prev) => [...prev, { name: '', value: undefined, control: undefined }]);
    };

    const handleRemoveFlag = (index: number) => {
        setFlagPairs((prev) => {
            const updatedFlagsOnPage = [...prev];
            updatedFlagsOnPage.splice(index, 1);
            return updatedFlagsOnPage.length === 0 ? [{ name: '', value: undefined, control: undefined }] : updatedFlagsOnPage;
        });
    };

    const onSwitchInputMode = () => {
        if (isJSONInput) {
            const newFlagPairs = jsonToPairs(flagsString);
            const newConfigPairs = jsonToPairs(controlString);

            newFlagPairs.forEach((item, index) => {
                item.control = newConfigPairs[index].value;
            });
            setFlagPairs(newFlagPairs);
        } else {
            const newFlagString = pairsToJSON(flagPairs);
            const newControlString = pairsToJSON(flagPairs.map((item) => ({ name: item.name, value: item.control })));
            setFlagsString(newFlagString);
            setControlString(newControlString);
        }
        setIsJSONInput.toggle();
    };

    // ========================= Render =========================
    const renderValidateBtn = () => (
        <PrimaryButton
            onClick={validateFeatureFlags}
            disabled={
                !flagSchemas ||
                (flagsErrorMessage.length > 0 && flagsErrorMessage !== flagNotValidatedMessage) ||
                flagsValidationMessage.type !== 'none'
            }
            styles={{
                root: {
                    ...(buttonStyles.root as object),
                    // GreenCyan10
                    backgroundColor: '#00ad56'
                },
                // slightly darker than GreenCyan10
                rootHovered: { backgroundColor: '#00984b' }
            }}
        >
            {flagsValidationMessage.type === 'none'
                ? 'Validate'
                : flagsValidationMessage.type === 'loading'
                  ? 'Validating...'
                  : 'Validated'}
        </PrimaryButton>
    );

    const renderJsonInput = () => (
        <Stack tokens={gapStackTokensMedium}>
            <Stack horizontal tokens={gapStackTokensMedium}>
                <TextField
                    id="flagsJSONText"
                    name="flagsJSON"
                    placeholder="List flags in JSON format"
                    value={flagsString}
                    multiline
                    componentRef={textFieldRef}
                    styles={textFieldStyles}
                    onChange={handleFlagsJSONChange}
                    disabled={!workItemFeatureFlags}
                />
                <TextField
                    id="controlConfigJSONText"
                    name="controlConfigJSON"
                    placeholder="List control configs in JSON format"
                    value={controlString}
                    multiline
                    componentRef={textFieldRef}
                    styles={textFieldStyles}
                    onChange={handleControlConfigJSONChange}
                    disabled={!workItemFeatureFlags}
                />
            </Stack>
            {!validationDisabled && renderValidateBtn()}
        </Stack>
    );

    const renderFlagInputByType = (flag: FlagPair, index: number, isControl = false) => {
        const currentValue = isControl ? flag.control : flag.value;
        if (!flag.name || !flagSchemas || validationDisabled) {
            return (
                <TextField
                    value={currentValue?.toString() ?? ''}
                    styles={pairInputStyle}
                    placeholder={currentValue?.length === 0 ? '(empty)' : !isControl ? 'Flag value' : 'Control value'}
                    onChange={(event, value) => {
                        handleFlagPairChange(index, flag.name, value, isControl);
                    }}
                    disabled={!workItemFeatureFlags || (!validationDisabled && !flagSchemas)}
                />
            );
        }
        const schema = flagSchemas[flag.name];
        if (schema?.type === 'boolean') {
            return (
                <Dropdown
                    selectedKey={currentValue?.toString() ?? null}
                    styles={pairInputStyle}
                    placeholder="Select boolean"
                    options={[
                        { key: 'true', text: 'true' },
                        { key: 'false', text: 'false' }
                    ]}
                    onChange={(ev, option) => {
                        handleFlagPairChange(index, flag.name, option?.key.toString(), isControl);
                    }}
                />
            );
        } else if (schema?.enum && schema.type !== 'array') {
            return (
                <Dropdown
                    placeholder="Select an option"
                    styles={pairInputStyle}
                    options={schema.enum.map((item: string | number) => ({ key: item, text: item.toString() }))}
                    selectedKey={(currentValue as string | number | undefined) ?? null}
                    onChange={(ev, option) => handleFlagPairChange(index, flag.name, option?.key.toString(), isControl)}
                    disabled={!workItemFeatureFlags}
                />
            );
        } else if (schema === undefined) {
            return <TextField value={''} styles={pairInputStyle} placeholder={'Please fetch schema'} disabled readOnly />;
        } else {
            return (
                <TextField
                    value={currentValue?.toString() ?? ''}
                    styles={pairInputStyle}
                    placeholder={currentValue?.length === 0 ? '(empty)' : !isControl ? 'Flag value' : 'Control value'}
                    onChange={(event, value) => {
                        handleFlagPairChange(index, flag.name, value, isControl);
                    }}
                    disabled={!workItemFeatureFlags}
                    multiline={schema !== null && schema.type !== 'integer' && schema.type !== 'number'}
                    autoAdjustHeight
                />
            );
        }
    };

    const renderPairInput = () => (
        <Stack tokens={gapStackTokensMedium}>
            {flagPairs.map((flag, index) => {
                return (
                    <Stack horizontal key={index} tokens={gapStackTokensMedium} verticalAlign="start">
                        <ComboBox
                            selectedKey={flag.name === '' ? null : flag.name}
                            useComboBoxAsMenuWidth={false}
                            styles={{
                                ...pairInputStyle
                            }}
                            placeholder="Select flag name"
                            options={flagOptions}
                            onChange={(event, option, optionIndex, value) => {
                                handleFlagPairChange(index, value, flag.value);
                            }}
                            autoComplete="on"
                            disabled={!workItemFeatureFlags || (!validationDisabled && !flagSchemas)}
                            allowFreeform
                        />
                        <TooltipHost
                            content={flagSchemas?.[flag.name] ? <pre>{JSON.stringify(flagSchemas[flag.name], null, 3)}</pre> : ''}
                            closeDelay={300}
                        >
                            {renderFlagInputByType(flag, index)}
                        </TooltipHost>
                        <TooltipHost content={'Control value (used only if eligible for experiment)'}>
                            {renderFlagInputByType(flag, index, true)}
                        </TooltipHost>

                        <Stack horizontal verticalAlign="center" tokens={gapStackTokensSmall}>
                            {(flagPairs.length > 1 || flagPairs[0].name || flagPairs[0].value || flagPairs[0].control) && (
                                <IconButton iconProps={removeIcon} onClick={() => handleRemoveFlag(index)} />
                            )}
                            {flagNames.filter((name) => name === flag.name).length >= 2 && (
                                <TooltipHost content="Duplicated flag name.">
                                    <Icon iconName="Warning" styles={{ root: { color: 'red', paddingRight: '10px' } }} />
                                </TooltipHost>
                            )}
                            {flag.name && !validationDisabled && flagSchemas && flagSchemas[flag.name] === null && (
                                <TooltipHost content="Flag not specified in schema.">
                                    <Icon iconName="Warning" styles={{ root: { color: '#ffaa44' } }} />
                                </TooltipHost>
                            )}
                        </Stack>
                    </Stack>
                );
            })}
            <Stack horizontal tokens={gapStackTokensMedium}>
                <PrimaryButton styles={buttonStyles} onClick={handleAddFlag}>
                    + Add Flag
                </PrimaryButton>
                {!validationDisabled && renderValidateBtn()}
                <DefaultButton styles={buttonStyles} onClick={generateControls}>
                    Generate Controls
                </DefaultButton>
            </Stack>
        </Stack>
    );

    return (
        <Stack tokens={gapStackTokensSmall}>
            <Stack horizontal>
                <ActionButton
                    iconProps={{ iconName: 'Switch' }}
                    onClick={onSwitchInputMode}
                    text={isJSONInput ? 'Switch to text box' : 'Switch to JSON'}
                    disabled={workItemFeatureFlags && isJSONInput && (!isValidJSON(flagsString) || !isValidJSON(controlString))}
                />
                {!isJSONInput && !validationDisabled && (
                    <ActionButton
                        iconProps={{ iconName: 'Download' }}
                        onClick={() =>
                            fetchSchema(
                                flagSchemas ? flagNames.filter((flag) => flagSchemas[flag] === undefined) : workItemFeatureFlags ?? []
                            )
                        }
                        text={'Fetch schemas'}
                        disabled={
                            !workItemFeatureFlags ||
                            !flagSchemas ||
                            (flagSchemas && !flagNames.some((flag) => flagSchemas[flag] === undefined))
                        }
                    />
                )}
            </Stack>
            <Stack.Item>
                {!flagSchemas && workItemFeatureFlags && !validationDisabled && <ProgressIndicator label={`Fetching flag schemas`} />}
            </Stack.Item>
            <MessageBar delayedRender={false} messageBarType={MessageBarType.info} styles={messageBarStyles}>
                You may add flags NOT included in the ADO work item by typing in the flag name box ONLY IF you are blocked by character
                limit.
            </MessageBar>
            {isJSONInput ? renderJsonInput() : renderPairInput()}
            {['success', 'error'].includes(flagsValidationMessage.type) && (
                <MessageBar
                    delayedRender={false}
                    messageBarType={flagsValidationMessage.type === 'success' ? MessageBarType.success : MessageBarType.error}
                    styles={messageBarStyles}
                    isMultiline
                    onDismiss={() => setFlagsValidationMessage({ type: 'none', message: '' })}
                >
                    <Stack tokens={gapStackTokensSmall}>
                        {flagsValidationMessage.message.split('\n').map((message, index) => (
                            <Text key={index} variant="small">
                                {message}
                            </Text>
                        ))}
                    </Stack>
                </MessageBar>
            )}
            {schemaErrorMessage && (
                <MessageBar delayedRender={false} messageBarType={MessageBarType.error} styles={messageBarStyles}>
                    {schemaErrorMessage}
                </MessageBar>
            )}
            {flagsErrorMessage !== '' && flagSchemas && (
                <MessageBar delayedRender={false} messageBarType={MessageBarType.error} styles={messageBarStyles}>
                    {flagsErrorMessage}
                    {flagsErrorMessage === flagNotValidatedMessage && (
                        <Link
                            href="https://domoreexp.visualstudio.com/Teamspace/_wiki/wikis/Teamspace.wiki/60350/Using-the-Feature-Flighting-v2-Tooling?anchor=feature-flag-schema"
                            target="_blank"
                        >
                            (details)
                        </Link>
                    )}
                </MessageBar>
            )}

            {flagsWarningMessage !== '' && (
                <MessageBar delayedRender={false} messageBarType={MessageBarType.warning} styles={messageBarStyles}>
                    {flagsWarningMessage}
                </MessageBar>
            )}
        </Stack>
    );

    function fetchSchema(flags: string[]) {
        const emptyFlags: FeatureFlags = {};
        flags.forEach((flag) => {
            if (flagSchemas && flagSchemas[flag] !== undefined) return;
            emptyFlags[flag] = '';
        });
        new FeatureFlightService()
            .validateFeatureFlags(emptyFlags, true)
            .then((response: ValidateFeatureFlagResponse) => {
                for (const flag in emptyFlags) {
                    if (response.flagSchemas[flag] === undefined) {
                        response.flagSchemas[flag] = null;
                    }
                }
                setFlagSchemas({ ...flagSchemas, ...response.flagSchemas });
            })
            .catch((error) => {
                console.error('Error fetching flag schemas: ', error);
                appInsightsClient.logException(
                    { exception: error },
                    {
                        message: `Caught error in useEffect validateFeatureFlags in Flags input`
                    }
                );
            });
    }

    function generateControls() {
        const newFlagPairs = [...flagPairs];
        newFlagPairs.forEach((item) => {
            if (!!item.control?.toString().length || item.value === undefined) return item.control;
            const flagType = flagSchemas?.[item.name]?.type;
            if (flagType === 'boolean') {
                item.control = item.value === 'true' ? 'false' : 'true';
            } else if (flagType === 'number') {
                item.control = '0';
            } else {
                item.control = '';
            }
        });
        setFlagPairs(newFlagPairs);
        setControlString(pairsToJSON(newFlagPairs.map((item) => ({ name: item.name, value: item.control }))));
    }

    function getDefaultFlagPairs() {
        const pairs = jsonToPairs(defaultFlags);
        const configPairs = jsonToPairs(defaultControlConfigs);
        pairs.forEach((item, idx) => {
            item.control = configPairs[idx].value;
        });
        return pairs;
    }

    function validateFeatureFlags() {
        const flags = isJSONInput ? flagsString : pairsToJSON(flagPairs);
        setFlagsValidationMessage({ type: 'loading', message: 'Validating flags' });
        new FeatureFlightService()
            .validateFeatureFlags(JSON.parse(flags))
            .then((response: ValidateFeatureFlagResponse) => {
                setFlagSchemas({ ...flagSchemas, ...response.flagSchemas });
                if (!response.isValid) {
                    setFlagsErrorMessage('');
                    setFlagsValidationMessage({
                        type: 'error',
                        message: response.error
                            .map((error) => {
                                if (error.message === 'should NOT have additional properties') {
                                    return 'One or more flag names are invalid. Please be sure category is included.';
                                }
                                return `${error.dataPath.substring(1)} ${error.message}`;
                            })
                            .join('\n')
                    });
                } else {
                    const controls = isJSONInput
                        ? controlString
                        : pairsToJSON(flagPairs.map((item) => ({ name: item.name, value: item.control })));
                    new FeatureFlightService().validateFeatureFlags(JSON.parse(controls)).then((res: ValidateFeatureFlagResponse) => {
                        setFlagsErrorMessage('');
                        if (!res.isValid) {
                            setFlagsValidationMessage({
                                type: 'error',
                                message: res.error.map((error) => `${error.dataPath.substring(1)} ${error.message}`).join('\n')
                            });
                        } else {
                            setFlagsValidationMessage({ type: 'success', message: 'Flags and controls are valid' });
                            props.update(flags, controls);
                        }
                    });
                }
            })
            .catch((error) => {
                setFlagsValidationMessage({
                    type: 'error',
                    message: 'Error validating flags. Please specify the flag in the format <category>.<flagName>.'
                });
                console.error('Error fetching flag schemas: ', error);
                appInsightsClient.logException(
                    { exception: error },
                    {
                        message: `Caught error in useEffect validateFeatureFlags in Flags input`
                    }
                );
            });
    }

    function pairsToJSON(nameValuePair: FlagPair[]): string {
        const result = JSON.stringify(
            nameValuePair.reduce((acc, pair) => {
                if (pair.name === '' && pair.value === undefined) return acc;
                if (validationDisabled) {
                    if (pair.value === 'true' || pair.value === 'false') {
                        return { ...acc, [pair.name]: pair.value === 'true' };
                    }
                    if (pair.value && !isNaN(parseFloat(pair.value))) {
                        return { ...acc, [pair.name]: Number(pair.value) };
                    }
                    return { ...acc, [pair.name]: pair.value };
                } else if (pair.value) {
                    const rawFlagType = flagSchemas?.[pair.name]?.type;
                    if (rawFlagType === undefined) {
                        return { ...acc, [pair.name]: pair.value };
                    }
                    // type may be a list or string
                    const flagType = typeof rawFlagType === 'string' ? [rawFlagType] : rawFlagType;
                    if (pair.value === 'null' && flagType.includes('null')) {
                        return { ...acc, [pair.name]: null };
                    }
                    if (['true', 'false'].includes(pair.value) && flagType.includes('boolean')) {
                        return { ...acc, [pair.name]: pair.value === 'true' };
                    }
                    if (!isNaN(parseFloat(pair.value)) && (flagType.includes('number') || flagType.includes('integer'))) {
                        return { ...acc, [pair.name]: Number(pair.value) };
                    }
                    if (pair.value[0] === '[' && pair.value[pair.value.length - 1] === ']' && flagType.includes('array')) {
                        const valueString = `{ "${pair.name}": ${pair.value} }`;
                        if (isValidJSON(valueString)) {
                            return {
                                ...acc,
                                ...JSON.parse(valueString)
                            };
                        }
                    }
                    if (isValidJSON(pair.value) && flagType.includes('object')) {
                        return { ...acc, [pair.name]: JSON.parse(pair.value) };
                    }
                }
                return { ...acc, [pair.name]: pair.value };
            }, {})
        );
        return result === '{}' ? '' : result;
    }
};

function jsonToPairs(jsonString: string): FlagPair[] {
    if (jsonString.length === 0 || !isValidJSON(jsonString)) return [{ name: '', value: undefined }];
    const convertedPair = Object.entries(flattenJSON(JSON.parse(jsonString))).map(([name, value]) => {
        const convertedValue = typeof value === 'object' ? JSON.stringify(value) : value;
        return { name, value: convertedValue } as FlagPair;
    });
    return convertedPair.length > 0 ? convertedPair : [{ name: '', value: undefined }];
}

const textFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 350 } };
const messageBarStyles: Partial<IMessageBarStyles> = {
    root: {
        alignItems: 'flex-start',
        marginTop: '5px',
        width: 500
    }
};
export default FlagsInput;
