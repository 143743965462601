import RingmasterRequestDetailsObject from '../../../services/models/Ringmaster/RingmasterRequestDetailsObject';

export type UserInfo = {
    id: string;
    principalName: string;
    displayName: string;
};

export interface RingChangeRequestFormData {
    workItemId: string;
    identityType: string;
    ring: string;
    cloud: string;
    operation: string;
    operationId: string;
    createdBy: UserInfo;
    businessJustification: string;
    approvedBy: string;
    teamName: string;
}

export interface FindIdFormData {
    operationId: string;
    requestedBy: UserInfo;
}

export interface RingmasterRequestDetailsItem extends RingmasterRequestDetailsObject {
    key: number;
    statusDate: string;
}

export interface RMDropdownOptionType {
    key: string;
    text: string;
}

// eslint-disable-next-line no-shadow
export enum RingmasterRequestStatus {
    Pending = 'Pending',
    Approved = 'Approved',
    Rejected = 'Rejected'
}
