import { Pivot, PivotItem, Link, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';

import { Card, CardHeader } from '../../components/Card';
import { displayName, ringmasterRequestApprovalGroup } from '../../services/configs/msGraph.defaults';
import MsGraphService from '../../services/msGraph.service';
import { appInsightsClient } from '../../utils/appInsightsUtility';
import { getUserDisplayName, getUserPrincipalName } from '../../utils/userUtility';

import FindIdForm from './FindIdForm';
import RequestForm from './RequestForm';
import RequestsStatusDetails from './RequestsStatusDetails';
import { gapStackTokensMedium } from './styles/RingmasterPageStyle';

/**
 * This component is the main page for Ringmaster UI. It contains 3 tabs: Find, Request, and Requests Status.
 * The Find tab is to get the ring details for a given ID.
 * The Request tab is to request a ring change for a given ID.
 * The Requests Status tab is to view the status of the requests made by the user.
 *
 * @returns The actual view.
 */
const RingAssignmentPage: React.FC = () => {
    const userPrincipalName = getUserPrincipalName();

    // ============================== State ==============================
    const [activeTab, setActiveTab] = useState('find-id');

    const [
        isRingmasterRequestApprovalGroupMember,
        { setTrue: setIsRingmasterRequestApprovalGroupMember, setFalse: setIsNotRingmasterRequestApprovalGroupMember }
    ] = useBoolean(false);

    const msGraphService = new MsGraphService();

    const handleTabChange = (item?: PivotItem) => {
        if (item) {
            setActiveTab(item.props.itemKey || '');
        }
    };

    // ============================== Hooks ==============================
    useEffect(() => {
        Promise.all([msGraphService.getTransitiveMemberOf(`${displayName} eq '${ringmasterRequestApprovalGroup}'`, 'id, displayName')])
            .then(([adminGroups]) => {
                adminGroups && adminGroups.length > 0
                    ? setIsRingmasterRequestApprovalGroupMember()
                    : setIsNotRingmasterRequestApprovalGroupMember();
            })
            .catch((fail) => {
                console.error(
                    `Caught error getting membership details for user: ${getUserDisplayName()} - ${userPrincipalName} and group: , ${ringmasterRequestApprovalGroup}], error: ${fail}`
                );
                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect getTransitiveMemberOf in RingAssignmentPage`,
                        user: userPrincipalName,
                        groups: [ringmasterRequestApprovalGroup]
                    }
                );
            });
    }, []);

    return (
        <Stack tokens={gapStackTokensMedium}>
            <Card>
                <CardHeader>
                    Ring Management Center - Test Account(s) <Text variant="small">(MVP)</Text>
                </CardHeader>
            </Card>
            <Card>
                <CardHeader>Note(s) :</CardHeader>
                <div style={{ marginTop: '10px' }}>
                    <Text variant="medium">
                        <strong>Ring master empowers you to 💪</strong>
                    </Text>
                </div>
                <ul>
                    <li>
                        <Text variant="medium">
                            🚀 Provide Teams inner ring access to your test user/tenant.{' '}
                            <Link
                                href="https://domoreexp.visualstudio.com/Teamspace/_wiki/wikis/Teamspace.wiki/64998/Ringmaster-Portal-Guide-Manage-Test-Account-(s)"
                                target="_blank"
                            >
                                Click here for Portal Guide.
                            </Link>
                        </Text>
                    </li>
                    <li>
                        <Text variant="medium">⚒️ Manage test users/tenants effectively to test your features.</Text>
                    </li>
                    <li>
                        <Text variant="medium">🛡️ Guardrails implemented where requests may be blocked in the following scenarios:</Text>
                        <ul>
                            <li>
                                <Text variant="medium">Requests in rings are blocked if prod IDs are detected.</Text>
                            </li>
                            <li>
                                <Text variant="medium">
                                    Test users are not allowed in Ring0, Ring0_s, Ring1, or Ring2, only managed via Security Groups.
                                </Text>
                            </li>
                            <li>
                                <Text variant="medium">For above mentioned rings, tenant movement are supported.</Text>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div>
                    <Text variant="medium">
                        <strong>Exceptions ⚠️</strong>
                    </Text>
                </div>
                <ul>
                    <li>
                        <Text variant="medium">
                            Checkout here for{' '}
                            <Link
                                href="https://domoreexp.visualstudio.com/Teamspace/_wiki/wikis/Teamspace.wiki/57358/Teams-Ringmaster#:~:text=Allowed%20Exceptions%20and%20its%20Process"
                                target="_blank"
                            >
                                allowed exception
                            </Link>
                            .
                        </Text>
                    </li>
                    <li>
                        <Text variant="medium">
                            If you need an exception on your &quot;Pending&quot; marked request create a post in{' '}
                            <Link href="https://aka.ms/teamsbqtrchannel" target="_blank">
                                BQTR Teams channel
                            </Link>{' '}
                            and attach ADO ticket with a valid reason.
                        </Text>
                    </li>
                    <li>
                        <Text variant="medium">
                            Reach out to us{' '}
                            <Link
                                href="https://teams.microsoft.com/l/channel/19%3A7c782828f2ee49c2aafe75c69c6fe929%40thread.skype/Ringmaster?groupId=af55e84c-dc67-4e48-9005-86e0b07272f9&tenantId=72f988bf-86f1-41af-91ab-2d7cd011db47"
                                target="_blank"
                            >
                                Teams Ringmaster
                            </Link>{' '}
                            for any assistance. For detailed guidance, check the{' '}
                            <Link href="https://aka.ms/ringmaster" target="_blank">
                                Teams Ringmaster Wiki
                            </Link>
                            .
                        </Text>
                    </li>
                </ul>
            </Card>
            <Card>
                <Pivot onLinkClick={handleTabChange} selectedKey={activeTab} overflowBehavior="menu">
                    <PivotItem headerText="Find" itemKey="find-id" />
                    <PivotItem headerText="Request" itemKey="request-ring-change" />
                    <PivotItem headerText="Requests Status" itemKey="requests-status" />
                </Pivot>
            </Card>
            {activeTab && activeTab === 'find-id' && (
                <Card>
                    <FindIdForm />
                </Card>
            )}
            {activeTab && activeTab === 'request-ring-change' && (
                <Card>
                    <RequestForm />
                </Card>
            )}
            {activeTab && activeTab === 'requests-status' && (
                <Card>
                    <RequestsStatusDetails isRingmasterRequestApprovalGroupMember={isRingmasterRequestApprovalGroupMember} />
                </Card>
            )}
        </Stack>
    );
};

export default appInsightsClient.withAITracking(RingAssignmentPage, 'RingAssignmentPage');
