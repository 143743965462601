import { ITextField, TextField } from '@fluentui/react';
import React, { createRef, useEffect } from 'react';

import { textFieldStyles } from '../styles/RingmasterPageStyle';

type TextInputProps = {
    setText: (val: string) => void;
    resetInput: boolean;
    multiline?: boolean;
    placeholder: string;
};

/**
 * Renders a text field which takes plain text as input.
 *
 * @param props A set of props for the component.
 * @returns A textual field for the user.
 */
const TextInput: React.FC<TextInputProps> = (props) => {
    // ========================= State =========================
    const [textValue, setTextValue] = React.useState<string>('');

    const textFieldRef = createRef<ITextField>();
    // ========================= Hooks =========================
    useEffect(() => {
        setTextValue('');
    }, [props.resetInput]);

    // ========================= Event handlers =========================
    const onChangeOfTextValue = (value: string): void => {
        setTextValue(value);
        props.setText(value);
    };

    // ========================= Render =========================
    return (
        <TextField
            styles={textFieldStyles}
            placeholder={props.placeholder}
            value={textValue}
            componentRef={textFieldRef}
            multiline={props.multiline}
            onChange={(event, value) => {
                if (value !== undefined) {
                    onChangeOfTextValue(value);
                }
            }}
        />
    );
};

export default TextInput;
