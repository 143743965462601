import { Label, Stack } from '@fluentui/react';
import React, { useState } from 'react';

import { appInsightsClient } from '../../utils/appInsightsUtility';
import { getUserInfo } from '../../utils/userUtility';

import {
    cloudDropdownOptions,
    defaultRingChangeRequestForm,
    idTypeDropdownOptions,
    operationDropdownOptions,
    ringDropdownOptions
} from './configs/defaults';
import DropdownInput from './RingAssignmentPageComponents/DropdownInput';
import IDInput from './RingAssignmentPageComponents/IDInput';
import FormButtons from './RingAssignmentPageComponents/RequestFormButtons';
import TextInput from './RingAssignmentPageComponents/TextInput';
import { ringmasterRequestTableCellStyle, ringmasterRequestTableStyle } from './styles/RingmasterPageStyle';
import { RingChangeRequestFormData } from './types/Types';

/**
 * This component exposes the form to request a ring change for a given ID.
 * It appears on the RingAssignmentPage as a tab.
 *
 * @returns The actual view.
 */
const RequestForm: React.FC = () => {
    // ============================== State ==============================
    const [formData, setFormData] = useState<RingChangeRequestFormData>({ ...defaultRingChangeRequestForm, createdBy: getUserInfo() });

    const [clearFormData, setClearFormData] = useState<boolean>(false);

    // ============================== Functions ==============================

    const updateIdentityType = (idType: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            identityType: idType
        }));
    };

    const updateRing = (ring: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            ring: ring
        }));
    };

    const updateCloud = (cloud: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            cloud: cloud
        }));
    };

    const updateOperation = (operation: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            operation: operation
        }));
    };

    const updateOperationId = (operationId: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            operationId: operationId
        }));
    };

    const updateBusinessJustification = (businessJustification: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            businessJustification: businessJustification
        }));
    };

    const updateTeamName = (teamName: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            teamName: teamName
        }));
    };

    const updateApprovedBy = (approvedBy: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            approvedBy: approvedBy
        }));
    };

    return (
        <>
            <Stack style={ringmasterRequestTableStyle}>
                <Stack.Item>
                    <table>
                        <tbody>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Team Name</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <TextInput setText={updateTeamName} resetInput={clearFormData} placeholder="Provide your team name" />
                                </td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Identity Type:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <DropdownInput
                                        setDropdownOption={updateIdentityType}
                                        dropdownOptions={idTypeDropdownOptions}
                                        resetInput={clearFormData}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Ring:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <DropdownInput
                                        setDropdownOption={updateRing}
                                        dropdownOptions={ringDropdownOptions}
                                        resetInput={clearFormData}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Cloud:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <DropdownInput
                                        setDropdownOption={updateCloud}
                                        dropdownOptions={cloudDropdownOptions}
                                        resetInput={clearFormData}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Operation:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <DropdownInput
                                        setDropdownOption={updateOperation}
                                        dropdownOptions={operationDropdownOptions}
                                        resetInput={clearFormData}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Operation Id:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <IDInput setId={updateOperationId} resetInput={clearFormData} />
                                </td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Approved By</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <TextInput
                                        setText={updateApprovedBy}
                                        resetInput={clearFormData}
                                        placeholder="Alias of GEM/LT who approved this request"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Business Justification:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <TextInput
                                        setText={updateBusinessJustification}
                                        resetInput={clearFormData}
                                        multiline={true}
                                        placeholder="Provide a business justification"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Stack.Item>

                <FormButtons
                    formData={formData}
                    setFormData={setFormData}
                    resetFormDataTrigger={setClearFormData}
                    resetData={clearFormData}
                />
            </Stack>
        </>
    );
};

export default appInsightsClient.withAITracking(RequestForm, 'RequestForm');
