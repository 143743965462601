import { Link, Text } from '@fluentui/react';
import React from 'react';

import { Card, CardHeader } from '../../../components/Card';
import { getADOWorkItemURL } from '../../../utils/adoUtility';
import {
    configCategoryDisplayName,
    desktopTCNS,
    desktopTCNSDisplayName,
    desktopVersion,
    experienceBuild,
    platformNames,
    skypeAClientPlatform,
    userOverrideCategory,
    userOverrideCategoryDisplayName,
    webRWC,
    webRWCDisplayName
} from '../configs/defaults';
import { FilterOperation, ProcessedFlightRollout } from '../types/Types';
import { capitalizeFirstLetter, getClient, parseAndRenderList } from '../utilities/FFv2Utils';

type FlightDetailsCardProps = {
    flight: ProcessedFlightRollout;
};

/**
 * Displays a card with essential flight information.
 *
 * @param props The props for the component.
 * @returns The flight info card.
 */
const FlightDetailsCard: React.FC<FlightDetailsCardProps> = (props) => {
    const client: string = props.flight.experience ? webRWCDisplayName : desktopTCNSDisplayName;
    const category: string = props.flight.category === userOverrideCategory ? userOverrideCategoryDisplayName : configCategoryDisplayName;
    // handle legacy data
    const platformFilter = props.flight.filters[skypeAClientPlatform];
    const platforms: string = parseAndRenderList(
        platformFilter.value,
        (id) => `${platformNames[parseInt(id) as keyof typeof platformNames]} (${id})`
    );

    const makePrettyIfArray = (value: string | number | boolean | string[] | number[]) =>
        Array.isArray(value) ? parseAndRenderList(value) : value.toString();

    return (
        <Card styles={{ root: { width: '40%' } }}>
            <CardHeader>Flight details</CardHeader>
            <table cellSpacing={8}>
                <tbody>
                    <tr>
                        <td>
                            <Text>Flight ID</Text>
                        </td>
                        <td>
                            <Text>
                                <Link href={props.flight.ECSLink} target="_blank">
                                    {props.flight.id}
                                </Link>
                            </Text>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Text>Flight type</Text>
                        </td>
                        <td>
                            <Text>{capitalizeFirstLetter(props.flight.flightType)}</Text>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Text>Boarding pass ID</Text>
                        </td>
                        <td>
                            <Text>
                                <Link href={getADOWorkItemURL(props.flight.boardingPassId)} target="_blank">
                                    {props.flight.boardingPassId}
                                </Link>
                            </Text>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Text>ADO release ID</Text>
                        </td>
                        <td>
                            <Text>
                                <Link href={props.flight.ADOLink} target="_blank">
                                    {props.flight.releaseId}
                                </Link>
                            </Text>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Text>Area Path</Text>
                        </td>
                        <td>
                            <Text>{props.flight.areaPath}</Text>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Text>Audience</Text>
                        </td>
                        <td>
                            <Text>
                                {capitalizeFirstLetter(
                                    Array.isArray(props.flight.audience) ? props.flight.audience[0] : props.flight.audience
                                )}
                            </Text>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Text>Client</Text>
                        </td>
                        <td>
                            <Text>{client}</Text>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Text>Category</Text>
                        </td>
                        <td>
                            <Text>{category}</Text>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Text>Platform(s)</Text>
                        </td>
                        <td>
                            <Text>{platforms}</Text>
                        </td>
                    </tr>
                    {/* if the build version value contains a comma, its a range of versions, so add another field for maximum build version */}
                    {props.flight.minimumBuildVersion.includes(',') ? (
                        <>
                            <tr>
                                <td>
                                    <Text>Minimum Build Version</Text>
                                </td>
                                <td>
                                    <Text>{props.flight.minimumBuildVersion.slice(1, -1).split(',')[0]}</Text>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Text>Maximum Build Version</Text>
                                </td>
                                <td>
                                    <Text>{props.flight.minimumBuildVersion.slice(1, -1).split(',')[1]}</Text>
                                </td>
                            </tr>
                        </>
                    ) : (
                        <tr>
                            <td>
                                <Text>Minimum Build Version</Text>
                            </td>
                            <td>
                                <Text>{props.flight.minimumBuildVersion}</Text>
                            </td>
                        </tr>
                    )}

                    {Object.entries(props.flight.filters)
                        .filter(
                            ([filterName, _]) =>
                                filterName !== skypeAClientPlatform &&
                                (getClient(props.flight.filters) === webRWC || filterName !== desktopVersion) &&
                                (getClient(props.flight.filters) === desktopTCNS || filterName !== experienceBuild)
                        )
                        .map(([filterName, filterValue]) => (
                            <tr key={filterName}>
                                <td>
                                    <Text>{filterName}</Text>
                                </td>
                                <td>
                                    <Text>
                                        {filterValue.operation === FilterOperation.Equal ? '' : `${filterValue.operation} `}
                                        {makePrettyIfArray(filterValue.value)}
                                    </Text>
                                </td>
                            </tr>
                        ))}

                    {props.flight.userFilters &&
                        Object.entries(props.flight.userFilters).map(([filterName, filterValue]) => (
                            <tr key={filterName}>
                                <td>
                                    <Text>{filterName}</Text>
                                </td>
                                <td>
                                    <Text>
                                        {filterValue.operation === FilterOperation.Equal ? '' : `${filterValue.operation} `}
                                        {makePrettyIfArray(filterValue.value)}
                                    </Text>
                                </td>
                            </tr>
                        ))}
                    {props.flight.burnInPRs && props.flight.burnInPRs.length > 0 && (
                        <tr key="burnInPRs">
                            <td>
                                <Text>Burn-in PRs</Text>
                            </td>
                            <td>
                                {props.flight.burnInPRs.map((x, index) => {
                                    return (
                                        <Text key={x.pullRequestId}>
                                            {index > 0 && ', '}
                                            <Link key={x.pullRequestId} href={x.prUrl} target="_blank">
                                                {x.pullRequestId}
                                            </Link>
                                        </Text>
                                    );
                                })}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Card>
    );
};

export default FlightDetailsCard;
