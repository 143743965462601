import { Link, Spinner, SpinnerSize } from '@fluentui/react';
import { Announced } from '@fluentui/react/lib/Announced';
import { DetailsList, DetailsListLayoutMode, IColumn, Selection, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { Text } from '@fluentui/react/lib/Text';
import { TextField } from '@fluentui/react/lib/TextField';
import { useBoolean } from '@fluentui/react-hooks';
import React, { FormEvent, ReactNode, useEffect, useState } from 'react';

import ErrorNotification from '../../components/ErrorNotification/ErrorNotification';
import RingmasterService from '../../services/ringmaster.service';
import { getADOWorkItemURL } from '../../utils/adoUtility';
import { appInsightsClient } from '../../utils/appInsightsUtility';

import { defaultRingmasterLookbackDays, ringmasterRequestDetailsColumns } from './configs/defaults';
import { RequestDetailsModal } from './RingAssignmentPageComponents/RequestDetailsModal';
import { exampleChildClass, textFieldStyles } from './styles/RingmasterPageStyle';
import { RingmasterRequestDetailsItem, UserInfo } from './types/Types';
import { getStatusDate, matchesRingmasterRequestDetailsItem } from './utilities/RingmasterUtils';

interface RingmasterRequestDetailsListProps {
    isRingmasterRequestApprovalGroupMember: boolean;
}

/**
 * This component exposes a view to show the status of the requests made by the user.
 * It appears on the RingAssignmentPage as a tab.
 *
 * @param props An object of type RingmasterRequestDetailsListProps.
 * @returns The actual view.
 */
const RequestsStatusDetails: React.FC<RingmasterRequestDetailsListProps> = (props) => {
    // ============================== State ==============================
    const [isDataLoaded, { setTrue: setIsDataLoaded, setFalse: setIsDataNotLoaded }] = useBoolean(false);
    const [isLoading, { setTrue: setIsLoading, setFalse: setIsNotLoading }] = useBoolean(true);

    const [allRequestItems, setAllRequestItems] = useState<RingmasterRequestDetailsItem[]>([]);
    const [selection, setSelection] = useState<Selection>(new Selection());

    const [displayedRequestItems, setDisplayedRequestItems] = React.useState<RingmasterRequestDetailsItem[]>(allRequestItems);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [refreshKey, setRefreshKey] = useState(0);

    const ringmasterService = new RingmasterService();

    // ============================== Hooks ==============================
    useEffect(() => {
        setIsLoading();
        setIsDataNotLoaded();

        ringmasterService
            .getAllHistoricalRequests(defaultRingmasterLookbackDays)
            .then((res) => {
                const allRequestItemsCpy: RingmasterRequestDetailsItem[] = [];
                res.forEach((request, index) => {
                    allRequestItemsCpy.push({
                        ...request,
                        key: index,
                        statusDate: getStatusDate(request)
                    });
                });

                allRequestItemsCpy.forEach((item) => {
                    Object.keys(item as unknown as Record<string, unknown>).forEach((key) => {
                        // if value is null, set it to 'N/A'
                        if ((item as unknown as Record<string, unknown>)[key] === null) {
                            (item as unknown as Record<string, unknown>)[key] = 'N/A';
                        }
                    });
                });

                allRequestItemsCpy.sort((a, b) => (new Date(a.createdTime) > new Date(b.createdTime) ? -1 : 1));
                setAllRequestItems(allRequestItemsCpy);
                setDisplayedRequestItems(allRequestItemsCpy);
                setIsDataLoaded();
            })
            .catch((error) => {
                setIsDataNotLoaded();
                appInsightsClient.logException(
                    { exception: error },
                    {
                        message: 'Caught error in useEffect getAllHistoricalRequests in RingAssignmentPage'
                    }
                );
            })
            .finally(() => {
                setIsNotLoading();
            });
    }, [refreshKey]);

    function onDismissModal() {
        setIsModalOpen(false);
    }

    // ============================== Functions ==============================

    const onFilter = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, text?: string): void => {
        setDisplayedRequestItems(
            text ? allRequestItems.filter((item) => matchesRingmasterRequestDetailsItem(text, item)) : allRequestItems
        );
    };

    const onItemInvoked = (): void => {
        setSelection(selection);
        setIsModalOpen(true);
    };

    const onRenderItemColumn = (item: RingmasterRequestDetailsItem, index?: number, column?: IColumn): ReactNode => {
        const fieldContent = item[column?.fieldName as keyof RingmasterRequestDetailsItem];
        const fieldContentString = String(fieldContent);

        switch (column?.key) {
            case 'workItemId':
                return <Link href={getADOWorkItemURL(fieldContentString)}>{fieldContentString}</Link>;
            case 'createdBy':
                return <span>{(fieldContent as UserInfo)['principalName']}</span>;

            default:
                return <span>{fieldContentString}</span>;
        }
    };

    return (
        <>
            {isLoading && <Spinner size={SpinnerSize.large} label="Loading data..." />}
            {!isLoading && !isDataLoaded && <ErrorNotification msg="Unable to fetch data" />}
            {!isLoading && isDataLoaded && (
                <>
                    <Text>Note: While focusing a row, pressing enter or double clicking will reveal more details.</Text>
                    <TextField className={exampleChildClass} label="Search by any field:" onChange={onFilter} styles={textFieldStyles} />
                    <Announced message={`Number of items after filter applied: ${displayedRequestItems.length}.`} />
                    <MarqueeSelection selection={selection}>
                        <DetailsList
                            items={displayedRequestItems}
                            columns={ringmasterRequestDetailsColumns}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            selection={selection}
                            selectionPreservedOnEmptyClick={true}
                            selectionMode={SelectionMode.single}
                            onItemInvoked={onItemInvoked}
                            onRenderItemColumn={onRenderItemColumn}
                        />
                    </MarqueeSelection>
                    <RequestDetailsModal
                        isOpen={isModalOpen}
                        onDismiss={onDismissModal}
                        updateRefreshKey={() => setRefreshKey(refreshKey + 1)}
                        selectedItem={selection?.getSelection()[0] as RingmasterRequestDetailsItem}
                        isRingmasterRequestApprovalGroupMember={props.isRingmasterRequestApprovalGroupMember}
                    />
                </>
            )}
        </>
    );
};

export default appInsightsClient.withAITracking(RequestsStatusDetails, 'RequestsStatusDetails');
