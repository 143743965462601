import { createContext } from 'react';

import { FreezeSettings, OperationStatus } from '../types/Types';

const FFv2Context = createContext<FFv2ContextType>({
    allCloudRings: []
});

export type FFv2ContextType = {
    operationStatus?: OperationStatus;
    freezeSettings?: FreezeSettings;
    refreshFreeze?: () => void;
    allCloudRings: string[];
};

export default FFv2Context;
