import {
    DefaultButton,
    Dropdown,
    DropdownMenuItemType,
    IconButton,
    IDropdownOption,
    Label,
    Modal,
    PrimaryButton,
    Stack
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useContext, useState } from 'react';

import FFv2Context from '../contexts/FFv2Context';
import { formStyles, gapStackTokensMedium, iconButtonStyles } from '../styles/FFv2Style';
import { cancelIcon } from '../styles/MyFlightsStyle';
import { FreezeSettings } from '../types/Types';

import { DateAndTimePicker } from './DateAndTimePicker';

type FreezeFormProps = {
    isOpen: boolean;
    onDismiss: () => void;
    setFreezeSettings: (freezeSettings: FreezeSettings) => void;
};

const emptyFreezeSettings = {
    indefinite: {
        cloudRings: []
    },
    period: {
        start: undefined,
        end: undefined,
        cloudRings: []
    }
} as unknown as FreezeSettings;

// eslint-disable-next-line no-shadow
enum CloudRingPresets {
    All = 'all',
    Ring3Plus = 'ring3+'
}

/**
 * FreezeForm component.
 *
 * @param props  - The props for the component.
 * @returns The rendered FreezeForm component.
 */
const FreezeForm: React.FC<FreezeFormProps> = (props) => {
    const ffv2Context = useContext(FFv2Context);
    const allR3PlusCloudRings = ffv2Context.allCloudRings.filter((cloudRing) => cloudRing.includes('ring3') || cloudRing.includes('ring4'));
    const [freezeSettingsForm, setFreezeSettingsForm] = useState<FreezeSettings>(getDefaultFreezeSettings());
    const [reset, { toggle: toggleReset }] = useBoolean(false);
    const today = new Date();

    const handleSubmit = () => {
        const settings = { ...freezeSettingsForm };
        settings.indefinite.cloudRings = settings.indefinite.cloudRings.filter(
            (ring) => ring !== CloudRingPresets.All && ring !== CloudRingPresets.Ring3Plus
        );
        settings.period.cloudRings = settings.period.cloudRings.filter(
            (ring) => ring !== CloudRingPresets.All && ring !== CloudRingPresets.Ring3Plus
        );
        props.setFreezeSettings(settings);
    };

    const handleChangeCloudRings = (freezeType: 'indefinite' | 'period', e?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (!option) return;
        const key = option?.key as string;
        const oldCloudRings = freezeSettingsForm[freezeType].cloudRings;
        let newCloudRings: string[] = [];
        if (key === CloudRingPresets.All) {
            if (oldCloudRings.includes(CloudRingPresets.All)) {
                newCloudRings = [];
            } else {
                newCloudRings = [CloudRingPresets.All, ...ffv2Context.allCloudRings];
            }
        } else if (key === CloudRingPresets.Ring3Plus) {
            if (oldCloudRings.includes(CloudRingPresets.Ring3Plus)) {
                newCloudRings = oldCloudRings.filter((ring) => ring !== CloudRingPresets.Ring3Plus);
            } else {
                newCloudRings = [
                    CloudRingPresets.Ring3Plus,
                    ...oldCloudRings.filter((ring) => !allR3PlusCloudRings.includes(ring)),
                    ...allR3PlusCloudRings
                ];
            }
        } else if (oldCloudRings.includes(key)) {
            newCloudRings = oldCloudRings.filter((ring) => ring !== key);
        } else {
            newCloudRings = [...oldCloudRings, key];
        }

        newCloudRings = [
            ...newCloudRings.filter((ring) => ring === CloudRingPresets.All || ring === CloudRingPresets.Ring3Plus),
            ...newCloudRings.filter((ring) => ring !== CloudRingPresets.All && ring !== CloudRingPresets.Ring3Plus).sort()
        ];

        setFreezeSettingsForm({
            ...freezeSettingsForm,
            [freezeType]: {
                ...freezeSettingsForm[freezeType],
                cloudRings: newCloudRings
            }
        });
    };

    return (
        <Modal isOpen={!!props.isOpen} onDismiss={props.onDismiss} isBlocking={false} className={formStyles.container}>
            <div className={formStyles.header}>
                <h3>Updating Freeze Settings</h3>
                <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={props.onDismiss} />
            </div>
            <div className={formStyles.body}>
                <Stack>
                    <Stack.Item>
                        <Label>Cloud Rings for Indefinite Freeze</Label>
                        <Dropdown
                            selectedKeys={freezeSettingsForm.indefinite.cloudRings}
                            multiSelect
                            options={getCloudRingOptions()}
                            onChange={(e, option) => handleChangeCloudRings('indefinite', e, option)}
                            styles={{
                                dropdown: { maxWidth: 300 }
                            }}
                        />
                    </Stack.Item>

                    <Stack.Item>
                        <Stack>
                            <Label
                                required={freezeSettingsForm.period.end !== undefined || freezeSettingsForm.period.cloudRings.length > 0}
                            >
                                Freeze Start Date:
                            </Label>
                            <Stack horizontal>
                                <DateAndTimePicker
                                    defaultDate={freezeSettingsForm.period.start ? new Date(freezeSettingsForm.period.start) : undefined}
                                    datePickerProps={{
                                        placeholder: 'Start date',
                                        ariaLabel: 'Start date',
                                        maxDate: freezeSettingsForm.period.end ? new Date(freezeSettingsForm.period.end) : undefined,
                                        isMonthPickerVisible: false,
                                        style: { minWidth: 160 }
                                    }}
                                    timePickerProps={{
                                        placeholder: 'Start time',
                                        ariaLabel: 'Start time',
                                        increments: 60
                                    }}
                                    onChange={(date) =>
                                        setFreezeSettingsForm({
                                            ...freezeSettingsForm,
                                            period: { ...freezeSettingsForm.period, start: date ? date.toISOString() : undefined }
                                        })
                                    }
                                    reset={reset}
                                />
                                <IconButton
                                    iconProps={cancelIcon}
                                    ariaLabel="Clear end date"
                                    onClick={() =>
                                        setFreezeSettingsForm({
                                            ...freezeSettingsForm,
                                            period: { ...freezeSettingsForm.period, start: undefined }
                                        })
                                    }
                                />
                            </Stack>
                        </Stack>

                        <Stack>
                            <Label
                                required={freezeSettingsForm.period.start !== undefined || freezeSettingsForm.period.cloudRings.length > 0}
                            >
                                Freeze End Date:
                            </Label>
                            <Stack horizontal>
                                <DateAndTimePicker
                                    defaultDate={freezeSettingsForm.period.end ? new Date(freezeSettingsForm.period.end) : undefined}
                                    datePickerProps={{
                                        placeholder: 'End date',
                                        ariaLabel: 'End date',
                                        minDate: freezeSettingsForm.period.start ? new Date(freezeSettingsForm.period.start) : today,
                                        isMonthPickerVisible: false,
                                        style: { minWidth: 160 }
                                    }}
                                    timePickerProps={{
                                        placeholder: 'End time',
                                        ariaLabel: 'End time',
                                        increments: 60
                                    }}
                                    onChange={(date) =>
                                        setFreezeSettingsForm({
                                            ...freezeSettingsForm,
                                            period: { ...freezeSettingsForm.period, end: date ? date.toISOString() : undefined }
                                        })
                                    }
                                    reset={reset}
                                />
                                <IconButton
                                    iconProps={cancelIcon}
                                    ariaLabel="Clear end date"
                                    onClick={() =>
                                        setFreezeSettingsForm({
                                            ...freezeSettingsForm,
                                            period: { ...freezeSettingsForm.period, end: undefined }
                                        })
                                    }
                                />
                            </Stack>
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <Label required={freezeSettingsForm.period.start !== undefined || freezeSettingsForm.period.end !== undefined}>
                            Cloud Rings for Period Freeze
                        </Label>
                        <Dropdown
                            selectedKeys={freezeSettingsForm.period.cloudRings}
                            multiSelect
                            options={getCloudRingOptions()}
                            onChange={(e, option) => handleChangeCloudRings('period', e, option)}
                            styles={{
                                dropdown: { maxWidth: 300 }
                            }}
                        />
                    </Stack.Item>
                    <br />

                    <Stack.Item>
                        <Stack horizontal tokens={gapStackTokensMedium}>
                            <PrimaryButton
                                onClick={handleSubmit}
                                disabled={
                                    !(
                                        (!!freezeSettingsForm.period.start &&
                                            !!freezeSettingsForm.period.end &&
                                            freezeSettingsForm.period.cloudRings.length > 0) ||
                                        (!freezeSettingsForm.period.start &&
                                            !freezeSettingsForm.period.end &&
                                            freezeSettingsForm.period.cloudRings.length === 0)
                                    ) ||
                                    (!!freezeSettingsForm.period.start &&
                                        !!freezeSettingsForm.period.end &&
                                        new Date(freezeSettingsForm.period.start) >= new Date(freezeSettingsForm.period.end))
                                }
                            >
                                Submit
                            </PrimaryButton>
                            <DefaultButton
                                onClick={() => {
                                    setFreezeSettingsForm(getDefaultFreezeSettings());
                                    toggleReset();
                                }}
                            >
                                Reset
                            </DefaultButton>
                            <DefaultButton
                                onClick={() => {
                                    setFreezeSettingsForm(emptyFreezeSettings);
                                }}
                            >
                                Clear
                            </DefaultButton>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </div>
        </Modal>
    );

    function getDefaultFreezeSettings(): FreezeSettings {
        if (ffv2Context.freezeSettings?.period.start !== undefined && ffv2Context.freezeSettings?.period.end !== undefined) {
            if (ffv2Context.freezeSettings?.period.cloudRings.length === 0) {
                return {
                    ...ffv2Context.freezeSettings,
                    period: {
                        ...ffv2Context.freezeSettings.period,
                        cloudRings: [CloudRingPresets.Ring3Plus, ...allR3PlusCloudRings]
                    }
                };
            } else {
                return ffv2Context.freezeSettings;
            }
        }
        return ffv2Context.freezeSettings ?? emptyFreezeSettings;
    }

    function getCloudRingOptions(): IDropdownOption[] {
        if (ffv2Context.allCloudRings.length === 0) return [];
        const options = [
            { key: 'presets', text: 'Presets', itemType: DropdownMenuItemType.Header },
            { key: CloudRingPresets.All as string, text: 'All' },
            { key: CloudRingPresets.Ring3Plus as string, text: 'Ring 3 above' }
        ];
        const clouds = [...new Set(ffv2Context.allCloudRings.map((cloudRing) => cloudRing.split('_')[0]))];
        clouds.forEach((cloud) => {
            options.push({ key: `divider_${cloud}`, text: '-', itemType: DropdownMenuItemType.Divider });
            options.push({ key: cloud, text: cloud.toLocaleUpperCase(), itemType: DropdownMenuItemType.Header });
            options.push(
                ...ffv2Context.allCloudRings
                    .filter((cloudRing) => cloudRing.startsWith(cloud + '_'))
                    .map((ring) => ({ key: ring, text: ring }))
            );
        });

        return options;
    }
};

export default FreezeForm;
