import { SupportedFilters } from '../../../services/models/FeatureFlight/SupportedFilters';
import { getUserId } from '../../../utils/userUtility';
import {
    desktopTCNS,
    desktopVersion,
    experience,
    experienceBuild,
    arrayTypeFilters,
    skypeAClientPlatform,
    webRWC,
    inProgressStatus,
    constantFilters
} from '../configs/defaults';
import {
    FeatureFlightRollout,
    FlightEnvironment,
    FilterData,
    FlightState,
    FlightUpdate,
    FlightUpdateType,
    ProcessedFlightRollout,
    UserInfo,
    Filters,
    EditFlightFormData,
    DataCache,
    FilterSchema,
    FilterOperation
} from '../types/Types';

function isValidFlight(flight: FeatureFlightRollout): boolean {
    const client = getClient(flight.filters);
    const minimumBuildVersionString = client === webRWC ? flight.filters[experienceBuild]?.value : flight.filters[desktopVersion]?.value;

    return !!minimumBuildVersionString;
}

/**
 * Checks if a string matches the GUID pattern.
 *
 * @param guid - The string to validate as a GUID.
 * @returns True if the input string is a valid GUID, false otherwise.
 */
export function isValidGuid(guid: string): boolean {
    const guidPattern = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/;
    return guidPattern.test(guid);
}

/**
 * Checks if a string matches the email pattern.
 *
 * @param email - The string to validate as an email.
 * @returns True if the input string is a valid email, false otherwise.
 */
export function isValidEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
}

/**
 * Validates a comma-separated list of some valid types.
 *
 * @param input - The input string to validate.
 * @param validTypes - The list of valid types to check against.
 * @returns True if the input string is a valid list of the specified types, false otherwise.
 */
export function validateListOfTypes(input: string, validTypes: ('guid' | 'email')[]): boolean {
    const items = input.split(',').map((item) => item.trim());
    return items.every((item) => {
        if (validTypes.includes('guid') && isValidGuid(item)) {
            return true;
        }
        if (validTypes.includes('email') && isValidEmail(item)) {
            return true;
        }
        return false;
    });
}

/**
 * Recursively flattens a nested JSON object.
 *
 * This function takes a nested JSON object and returns a new object with the properties
 * flattened to a single level using dot notation for the nested keys. If any property's value
 * is itself an object, the function will recursively flatten that object as well.
 *
 * @param data - The input JSON object to be flattened.
 * @returns The flattened object with properties in dot notation.
 */
export function flattenJSON(data: { [key: string]: unknown }) {
    // Error handling for invalid input
    if (typeof data !== 'object' || data === null) {
        throw new Error('Invalid input: data must be a non-null object');
    }

    const result: { [key: string]: unknown } = {};

    for (const i in data) {
        if (typeof data[i] === 'object' && Array.isArray(data[i]) === false) {
            const flatObject = flattenJSON(data[i] as { [key: string]: unknown });
            for (const x in flatObject) {
                result[i + '.' + x] = flatObject[x];
            }
        } else {
            result[i] = data[i];
        }
    }
    return result;
}

/**
 * Checks if a given string is a valid JSON string.
 * If the parsing is successful, the function returns true, indicating that the input is a valid JSON string.
 * If the parsing fails due to any syntax or other JSON-related errors, the function returns false.
 *
 * @param jsonString - The JSON string to be validated.
 * @returns True if the input string is a valid JSON, otherwise false.
 */
export function isValidJSON(jsonString: string): boolean {
    try {
        if (jsonString.length === 0) return true;
        const parsedJson = JSON.parse(jsonString);
        return typeof parsedJson === 'object' && parsedJson;
    } catch (error) {
        return false;
    }
}

/**
 * Converts an array of filters to a dictionary (object) representation.
 *
 * @param filtersList - An array of Filter objects to be converted to a dictionary.
 * @returns The dictionary representation of the filters.
 */
export const convertFiltersListToDict = (
    filtersList: FilterData[]
): { [filterName: string]: { operation: FilterOperation; value: string | string[] } } => {
    const result: { [filterName: string]: { operation: FilterOperation; value: string | string[] } } = {};

    for (const filter of filtersList) {
        if (arrayTypeFilters.includes(filter.name)) {
            result[filter.name] = { operation: filter.operation, value: filter.value.split(',').map((value) => value.trim()) };
        } else {
            result[filter.name] = { operation: filter.operation, value: filter.value };
        }
    }

    return result;
};

/**
 * Validates the given value based on the specified client.
 *
 * @param {string} client - The client identifier (e.g., "webRWC" or "desktopTCNS").
 * @param {string} value - The value to be validated.
 * @returns {boolean} True if the value is valid for the client, otherwise false.
 */
export const isValidClientVersion = (client: string, value: string): boolean => {
    const webVersionRegex = /^\d{11}$/;
    const desktopTcnsVersionRegex = /^\d{5}\.\d{3,4}\.\d{4}\.\d{4}$/;
    switch (client) {
        case webRWC:
            return webVersionRegex.test(value);
        case desktopTCNS:
            return desktopTcnsVersionRegex.test(value);
        default:
            return false;
    }
};

/**
 * Checks if a rollout name is valid.
 *
 * @param rolloutName - The rollout name to be validated.
 * @returns True if the rollout name is valid, false otherwise.
 */
export const isValidRolloutName = (rolloutName: string): boolean => {
    if (rolloutName.length < 5) return false;
    rolloutName = rolloutName.substring(4);

    const regex = /^[a-zA-Z0-9 ]*$/;
    if (rolloutName.length < 3 || rolloutName.length > 100 || !regex.test(rolloutName)) {
        return false;
    }
    return true;
};

/**
 * Compares two FlightState objects based on their properties and returns a comparison result.
 *
 * @param x - The first FlightState object to compare.
 * @param y - The second FlightState object to compare.
 * @param isSortedDescending - A boolean value indicating whether the comparison should be in descending order.
 * @returns A number indicating the comparison result. Returns 1 if x is greater than y, -1 if x is less than y, and 0 if they are equal.
 */
export function cmpState(x: FlightState, y: FlightState, isSortedDescending: boolean) {
    if (x.environment !== y.environment) {
        return (isSortedDescending ? x.environment < y.environment : x.environment > y.environment) ? 1 : -1;
    } else if (x.ring !== y.ring) {
        return (isSortedDescending ? x.ring < y.ring : x.ring > y.ring) ? 1 : -1;
    } else {
        return (isSortedDescending ? x.allocationPercentage < y.allocationPercentage : x.allocationPercentage > y.allocationPercentage)
            ? 1
            : -1;
    }
}

/**
 * Sorts the given array by the specified key.
 *
 * @param items - The array of items to sort.
 * @param key - The key to sort by.
 * @param isSortedDescending - The sort order.
 * @returns The sorted items.
 */
export function copyAndSort<T>(items: T[], key: string, isSortedDescending: boolean): T[] {
    const k = key as keyof T;

    return items.sort((a: T, b: T) => {
        if ('isSubscribed' in (a as object) && 'isSubscribed' in (b as object)) {
            const x = a as unknown as ProcessedFlightRollout;
            const y = b as unknown as ProcessedFlightRollout;
            if (x.isSubscribed !== y.isSubscribed) {
                return x.isSubscribed ? -1 : 1;
            }
        }
        if (a[k] === undefined || a[k] === null) return isSortedDescending ? 1 : -1;
        if (b[k] === undefined || b[k] === null) return isSortedDescending ? -1 : 1;
        if (a[k] instanceof Object) {
            if ('ring' in (a[k] as object) && 'allocationPercentage' in (a[k] as object) && 'environment' in (a[k] as object)) {
                const x = a[k] as unknown as FlightState;
                const y = b[k] as unknown as FlightState;
                return cmpState(x, y, isSortedDescending);
            }
            if ('displayName' in (a[k] as object)) {
                const x = a[k] as unknown as UserInfo;
                const y = b[k] as unknown as UserInfo;
                return (isSortedDescending ? x.displayName < y.displayName : x.displayName > y.displayName) ? 1 : -1;
            }
        }
        return (isSortedDescending ? a[k] < b[k] : a[k] > b[k]) ? 1 : -1;
    });
}

/**
 * Converts an environment string to the corresponding FlightEnvironment enum value.
 *
 * @param environmentString - The environment string to convert.
 * @returns The corresponding FlightEnvironment enum value.
 */
export function environmentStringToEnum(environmentString: string) {
    let environment: FlightEnvironment;

    switch (environmentString) {
        case 'gcch':
            environment = FlightEnvironment.GCCH;
            break;
        case 'dod':
            environment = FlightEnvironment.DoD;
            break;
        case 'ag08':
            environment = FlightEnvironment.AG08;
            break;
        case 'ag09':
            environment = FlightEnvironment.AG09;
            break;
        case 'gallatin':
            environment = FlightEnvironment.Gallatin;
            break;
        default:
            environment = FlightEnvironment.Prod;
    }
    return environment;
}

/**
 * Gets the state of the given flight.
 *
 * @param updates The flight updates.
 * @returns The state of the flight.
 */
export function getFlightStateFromUpdates(updates: FlightUpdate[]): FlightState {
    const allocationUpdates = updates.filter((update) => update.updateType === FlightUpdateType.allocationUpdate);
    if (allocationUpdates.length === 0) {
        return {
            ring: 'ring0',
            allocationPercentage: 0,
            environment: FlightEnvironment.Prod,
            stageName: 'Not Started',
            runningStatus: 'Queued',
            timestamp: Date.now()
        };
    }

    const latestAllocationUpdate = allocationUpdates[allocationUpdates.length - 1];
    const updateRequest = JSON.parse(JSON.stringify(latestAllocationUpdate.updateValue.request));
    const updateResponse = JSON.parse(JSON.stringify(latestAllocationUpdate.updateValue.response));

    const ring = updateResponse.filters.find((filter: FilterData) => filter.name === 'TeamsRing')?.value.split(',')[0];
    const environmentString = updateResponse.filters.find((filter: FilterData) => filter.name === 'Environment')?.value.split(',')[0];

    return {
        ring: ring === 'general' ? 'ring4' : ring,
        allocationPercentage: updateRequest.allocationPercentage,
        environment: environmentStringToEnum(environmentString),
        stageName: updateRequest.stageName,
        runningStatus: 'Running',
        timestamp: latestAllocationUpdate.timestamp
    };
}

/**
 * Takes the raw flight data and processes it to be displayed in the UI.
 *
 * @param flights - The flights to be processed.
 * @returns - The processed flights.
 */
export function processFlights(flights: FeatureFlightRollout[]): ProcessedFlightRollout[] {
    const processedFlights: ProcessedFlightRollout[] = [];

    flights.forEach((flight) => {
        if (!isValidFlight(flight)) return;

        // legacy data in database
        if (typeof flight.createdBy === 'string') return;
        if (flight.createdBy.displayName === undefined) flight.createdBy.displayName = '';

        const processedFlight: ProcessedFlightRollout = {
            id: flight.id,
            rolloutName: flight.rolloutName,
            rolloutId: flight.rolloutId,
            featureFlags: flight.featureFlags,
            audience: flight.audience,
            areaPath: flight.areaPath,
            client: getClient(flight.filters),
            category: flight.category,
            platforms: getPlatforms(flight.filters),
            experience: getExperience(flight.filters),
            minimumBuildVersion: getMinimumBuildVersion(flight.filters),
            flightType: flight.flightType,
            boardingPassId: flight.boardingPassId,
            filters: flight.filters,
            userFilters: flight.userFilters,
            createdBy: flight.createdBy,
            timestamp: flight.timestamp,
            lastUpdatedTimestamp: flight.lastUpdatedTimestamp ?? flight.timestamp,
            createdDate: new Date(flight.timestamp),
            releaseId: flight.releaseId,
            ADOLink: flight.ADOLink,
            ECSLink: flight.ECSLink,
            approval: flight.approval,
            state: { ...flight.currentState, environment: environmentStringToEnum(flight.currentState.environment as unknown as string) },
            experimentId: flight.experimentId,
            experimentLink: flight.experimentLink,
            controlConfigs: flight.controlConfigs,
            testBuilds:
                flight.testBuilds ??
                (flight.testBuildId
                    ? [
                          {
                              id: flight.testBuildId,
                              ring:
                                  flight.currentState.allocationPercentage === 100
                                      ? `ring${Number(flight.currentState.ring[4]) + 1}`
                                      : flight.currentState.ring
                          }
                      ]
                    : []),
            isSubscribed: flight.subscriberIds?.includes(getUserId()) ?? false,
            isScorecardEligible: flight.isScorecardEligible,
            scorecardEligibilityMessage: flight.scorecardEligibilityMessage,
            burnInPRs: flight.burnInPRs
        };
        processedFlights.push(processedFlight);
    });

    // sort flights by flight name

    return copyAndSort<ProcessedFlightRollout>(processedFlights, 'timestamp', true);
}

/**
 * Checks if a flight is in progress.
 *
 * @param flight - The processed flight rollout object.
 * @returns A boolean indicating whether the flight is in progress.
 */
export function isFlightInProgress(flight: ProcessedFlightRollout): boolean {
    return inProgressStatus.includes(flight.state.runningStatus);
}

/**
 * Filter flights of certain status.
 *
 * @param allFlights All flights.
 * @param status The flight status for filtering.
 * @param userId The current user ID.
 * @returns Filtered flights.
 */
export function filterFlightsByStatus(allFlights: ProcessedFlightRollout[], status: string, userId: string): ProcessedFlightRollout[] {
    if (status === 'all') {
        return allFlights;
    } else if (status === 'pendingApproval') {
        return allFlights.filter((flight) => flight.state.runningStatus === 'Pending Approval' && flight.createdBy.id !== userId);
    } else if (status === 'pendingRequest') {
        return allFlights.filter((flight) => flight.state.runningStatus === 'Pending Approval' && flight.createdBy.id === userId);
    } else if (status === 'inProgress') {
        return allFlights.filter((flight) => isFlightInProgress(flight));
    } else if (status === 'readyToBurnIn') {
        return allFlights.filter((flight) => flight.state.runningStatus === 'Ready To Burn In');
    } else if (status === 'recentlyCompleted') {
        return allFlights.filter(
            (flight) =>
                flight.state.runningStatus === 'Completed' && new Date(flight.state.timestamp).getDate() + 90 >= new Date().getDate()
        );
    } else if (status === 'cancelled') {
        return allFlights.filter((flight) => flight.state.runningStatus === 'Cancelled');
    } else {
        return [];
    }
}

/**
 * Filter flights of certain creators.
 *
 * @param allFlights All flights.
 * @param creatorIds IDs of the selected creators.
 * @returns Filtered flights.
 */
export function filterFlightsByCreators(allFlights: ProcessedFlightRollout[], creatorIds: string[]): ProcessedFlightRollout[] {
    return creatorIds.length ? allFlights.filter((flight) => creatorIds.includes(flight.createdBy.id)) : allFlights;
}

/**
 * Filter flights by search key.
 *
 * @param allFlights All flights.
 * @param searchKey The search key.
 * @returns Filtered flights.
 */
export function filterFlightsBySearchKey(allFlights: ProcessedFlightRollout[], searchKey: string): ProcessedFlightRollout[] {
    return searchKey.length
        ? allFlights.filter(
              (x: ProcessedFlightRollout) =>
                  x.rolloutName?.toLowerCase().includes(searchKey.toLocaleLowerCase()) ||
                  JSON.stringify(x.featureFlags).toLowerCase().includes(searchKey.toLocaleLowerCase()) ||
                  x.boardingPassId?.toString().includes(searchKey) ||
                  x.releaseId?.toString().includes(searchKey) ||
                  x.rolloutId?.toString().includes(searchKey) ||
                  x.experimentId?.toString().includes(searchKey) ||
                  x.id.includes(searchKey)
          )
        : allFlights;
}

/**
 * Filter flights by current state.
 *
 * @param allFlights All flights.
 * @param stages The selected stages.
 * @returns Filtered flights.
 */
export function filterFlightsByStages(allFlights: ProcessedFlightRollout[], stages: string[]): ProcessedFlightRollout[] {
    return stages.length
        ? allFlights.filter((flight) => stages.includes(flight.state ? flight.state.stageName : 'Not started'))
        : allFlights;
}

/**
 * Filter flights by date range.
 *
 * @param allFlights All flights.
 * @param startDate The start date.
 * @param endDate The end date.
 * @returns Filtered flights.
 */
export function filterFlightByDateRange(allFlights: ProcessedFlightRollout[], startDate?: Date, endDate?: Date): ProcessedFlightRollout[] {
    return allFlights.filter((flight) => (!startDate || flight.createdDate >= startDate) && (!endDate || flight.createdDate <= endDate));
}

/**
 * Filter flights based on the specified filters.
 *
 * @param allFlights All flights.
 * @param filters The filters to apply.
 * @param filters.status The selected status.
 * @param filters.creatorIds IDs of the selected creators.
 * @param filters.stages The selected stages.
 * @param filters.audiences The selected audiences.
 * @param filters.searchKey The search key.
 * @param filters.startDate The start date.
 * @param filters.endDate The end date.
 * @param filters.showOnlyBlocked Whether to show only blocked flights.
 * @param filters.showOnlyMyFlights Whether to show only flights created by me.
 * @param currentUser Information about current user.
 * @param teamMembers Information about team members.
 * @returns Filtered flights.
 */
export function filterFlights(
    allFlights: ProcessedFlightRollout[],
    filters: {
        status?: string[];
        creatorIds?: string[];
        stages?: string[];
        audiences?: string[];
        searchKey?: string;
        startDate?: Date;
        endDate?: Date;
        showOnlyBlocked?: boolean;
        showOnlyMyFlights?: boolean;
    },
    currentUser?: UserInfo,
    teamMembers?: UserInfo[]
): ProcessedFlightRollout[] {
    let result = allFlights;
    const { status, creatorIds, stages, searchKey, startDate, endDate, showOnlyBlocked, showOnlyMyFlights, audiences } = filters;

    if (status && status.length > 0) result = result.filter((flight) => status.includes(flight.state.runningStatus));
    if (creatorIds) result = filterFlightsByCreators(result, creatorIds);
    if (stages) result = filterFlightsByStages(result, stages);
    if (searchKey) result = filterFlightsBySearchKey(result, searchKey);
    if (startDate || endDate) result = filterFlightByDateRange(result, startDate, endDate);
    if (showOnlyBlocked) result = result.filter((flight) => flight.blockers && flight.blockers.length > 0);
    if (showOnlyMyFlights && currentUser && teamMembers) {
        result = result.filter(
            (flight) =>
                flight.createdBy.id === currentUser.id || teamMembers.some((x) => x.id === flight.createdBy.id || flight.isSubscribed)
        );
    }
    if (audiences && audiences.length > 0) result = result.filter((flight) => audiences.includes(flight.audience.toLocaleLowerCase()));
    return result;
}

/**
 * Returns the current iteration path based on the current date and the specified end period.
 *
 * @param end The end period of the iteration path. Defaults to 'month'.
 * @returns The current iteration path.
 */
export function getCurrentIterationPath(end = 'month') {
    const today = new Date();

    const year = today.getFullYear();
    const month = today.getMonth();
    const monthString = today.toLocaleString('default', { month: 'short' });

    const half = Math.floor(month / 6) + 1;
    const quarter = Math.floor(month / 3) + 1;

    let iterationPath = 'MSTeams';
    iterationPath += `\\${year}`;
    if (end === 'year') {
        return iterationPath;
    }
    iterationPath += `\\H${half}`;
    if (end === 'half') {
        return iterationPath;
    }
    iterationPath += `\\Q${quarter}`;
    if (end === 'quarter') {
        return iterationPath;
    }
    iterationPath += `\\${monthString}`;
    if (end === 'month') {
        return iterationPath;
    }
    return iterationPath;
}

/**
 * Capitalizes the first letter of the given string.
 *
 * @param string - The string to capitalize.
 * @returns The capitalized string.
 */
export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Parses and renders a list of elements. Used for compatibility with legacy backend.
 * Gives the same result: a string of elements separated by a comma and a space. The elements are formatted using the provided function.
 * If the input is already a string split by commas, the output is ensured to have exactly one space after each comma.
 *
 * @param elems - The elements to parse and render. Can be a string or an array of strings or another type used coming from the backend.
 * @param formatElement - An optional function to apply to format each element after parsing and before rendering.
 * @returns The parsed and rendered list of elements.
 */
export function parseAndRenderList(elems: string | number | boolean | string[] | number[], formatElement?: (elem: string) => string) {
    const asArr = typeof elems === 'string' ? elems.split(',').map((x) => x.trim()) : (elems as Array<string>);
    const identity = (x: string) => x;
    return asArr.map(formatElement ?? identity).join(', ');
}

/**
 * Returns the appropriate flight client based on the provided filters.
 *
 * @param filters - The filters to determine the flight client.
 * @returns The flight client.
 */
export function getClient(filters: Filters) {
    if (filters['Experience']) {
        return webRWC;
    } else {
        return desktopTCNS;
    }
}

/**
 * Retrieves the minimum build version based on the client and filters.
 *
 * @param filters The filters object.
 * @param client The client name.
 * @returns The minimum build version as a string.
 */
export function getMinimumBuildVersion(filters: Filters, client?: string): string {
    return (
        ((client ?? getClient(filters)) === webRWC
            ? (filters[experienceBuild].value as string)
            : (filters[desktopVersion].value as string)) ?? ''
    );
}

/**
 * Retrieves an array of platform IDs from the provided filters object.
 *
 * @param filters - The filters object containing platform information.
 * @returns An array of platform IDs.
 */
export function getPlatforms(filters: Filters): number[] {
    return filters[skypeAClientPlatform].value
        .toString()
        .split(',')
        .map((id) => parseInt(id));
}

/**
 * Retrieves the experience value from the given filters object.
 *
 * @param filters - The filters object containing the experience value.
 * @returns The experience value as a string, or undefined if not found.
 */
export function getExperience(
    filters: Filters & {
        Experience?: { operation: FilterOperation; value: string | string[] };
    }
): string[] | undefined {
    const experienceValue = filters[experience]?.value;
    if (typeof experienceValue === 'string') return experienceValue.split(',');
    if (Array.isArray(experienceValue)) return experienceValue;
    return undefined;
}

/**
 * Returns the default form data for editing a flight.
 * If a flight is provided, it populates the form with the flight's data.
 * If no flight is provided, it returns an empty form.
 *
 * @param flight - The flight to populate the form with.
 * @returns The default form data for editing a flight.
 */
export function getDefaultEditFlightFormData(flight?: ProcessedFlightRollout): EditFlightFormData {
    return flight
        ? {
              rolloutName: flight.rolloutName,
              filters: {
                  ...flight.filters,
                  [skypeAClientPlatform]: { operation: FilterOperation.Equal, value: flight.platforms }
              },
              userFilters: flight.userFilters,
              featureFlags: flight.featureFlags,
              controlConfigs: flight.controlConfigs ?? flight.featureFlags
          }
        : {
              rolloutName: '',
              filters: {
                  [skypeAClientPlatform]: { operation: FilterOperation.Equal, value: [] }
              },
              featureFlags: {},
              controlConfigs: {}
          };
}

/**
 * Creates a deep clone of an object.
 * This function uses JSON serialization to create a deep copy of the given object.
 *
 * @template T The type of the object being cloned.
 * @param object Object The object to be cloned.
 * @returns A new object that is a deep clone of the original object, preserving the original's type and structure.
 */
export function deepClone<T>(object: T): T {
    return JSON.parse(JSON.stringify(object));
}

/**
 * Retrieves cached data from the local storage based on the provided key.
 *
 * @param key - The key used to store the data in the local storage.
 * @returns The cached data if it exists and has not expired, otherwise undefined.
 */
export function getCachedData(key: string): DataCache | undefined {
    const cachedData = JSON.parse(localStorage.getItem(key) || 'null') as DataCache;
    if (!cachedData) {
        return undefined;
    }

    if (key === 'ffv2FlightData' || key === 'ffv2FlightData') {
        (cachedData.data as ProcessedFlightRollout[]).map((flight: ProcessedFlightRollout) => {
            flight.createdDate = new Date(flight.createdDate);
            return flight;
        });
    }

    const diffInMinutes = Math.round((Date.now() - cachedData.timestamp) / 1000 / 60);
    if (diffInMinutes >= cachedData.expirationMins) {
        localStorage.removeItem(key);
        return undefined;
    }

    return cachedData;
}

/**
 * Caches data in the local storage with an expiration time.
 *
 * @param key - The key to store the data under.
 * @param data - The data to be cached.
 * @param expirationMins - The expiration time in minutes (default is 5 minutes).
 */
export function cacheData(key: string, data: unknown, expirationMins = 5) {
    const processedData: DataCache = {
        data,
        timestamp: Date.now(),
        expirationMins
    };
    localStorage.setItem(key, JSON.stringify(processedData));
}

/**
 * Process filters from backend to front end schema.
 *
 * @param supportedFilters - The filters to be processed.
 * @returns The processed filters.
 */
export function processFilters(supportedFilters: SupportedFilters): FilterSchema[] {
    const updatedFilters: FilterSchema[] = [];
    if (supportedFilters) {
        Object.entries(supportedFilters).forEach(([key, value]) => {
            if (!constantFilters.includes(key)) {
                const filter: FilterSchema = {
                    key: key,
                    type: value.keys.value.type,
                    description: value.keys.value.flags?.description,
                    operations: value.keys.operation.allow as FilterOperation[]
                };
                if (value.keys.value.items && 'allow' in value.keys.value.items[0]) {
                    filter.options = value.keys.value.items[0].allow;
                }
                updatedFilters.push(filter);
            }
        });
    }

    return updatedFilters;
}

/**
 * Checks if objects are equal.
 *
 * @param obj1 Object to compare.
 * @param obj2 Object to compare.
 * @returns True if the objects are equal, otherwise false.
 */
export function areObjectsEqual(obj1?: object, obj2?: object): boolean {
    if (!obj1 && !obj2) {
        return true;
    }
    if (!obj1 || !obj2) {
        return false;
    }
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
        return JSON.stringify(obj1.sort()) === JSON.stringify(obj2.sort());
    }

    const flattenedObj1 = flattenJSON(obj1 as { [key: string]: unknown });
    const flattenedObj2 = flattenJSON(obj2 as { [key: string]: unknown });

    const keys1 = Object.keys(flattenedObj1);
    const keys2 = Object.keys(flattenedObj2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        if (flattenedObj1[key] !== flattenedObj2[key]) {
            return false;
        }
    }
    return true;
}
