import { Stack, ICommandBarItemProps, CommandBar, getTheme } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useContext } from 'react';

import FeatureFlightService from '../../../services/featureFlight.service';
import FFv2Context from '../contexts/FFv2Context';
import FlightFiltersBar from '../FlightsComponents/FlightFiltersBar';
import { gapStackTokensSmall } from '../styles/FFv2Style';
import { FlightStatusFilter, ProcessedFlightRollout, UserInfo, FlightFilters, FreezeSettings } from '../types/Types';

import EditFlightForm from './EditFlightForm';
import FreezeForm from './FreezeForm';

type AdminFlightsCommandBarProps = {
    flightFilters: FlightFilters;
    flightStages: string[];
    flightStatusFilter: FlightStatusFilter;
    handleRefreshData: () => void;
    updateFlight: (flight: ProcessedFlightRollout) => void;
    otherUsers: UserInfo[];
    handleFilterChange: (key: string, value: string | string[] | Date | undefined | boolean) => void;
    clearFilters: () => void;
    selectedFlight?: ProcessedFlightRollout;
};

/**
 * AdminFlightsCommandBar component.
 *
 * @param props - The props for the component.
 * @returns {React.ReactElement} The rendered AdminFlightsCommandBar component.
 */
const AdminFlightsCommandBar: React.FC<AdminFlightsCommandBarProps> = (props) => {
    const ffv2Context = useContext(FFv2Context);
    const { selectedFlight } = props;
    const theme = getTheme();
    const [isFilterOptionsOpen, { setTrue: showFilterOptions, setFalse: hideFilterOptions }] = useBoolean(true);
    const [isEditFlightModalOpen, { setTrue: showEditFlightModal, setFalse: hideEditFlightModal }] = useBoolean(false);
    const [isFreezeFormOpen, { setTrue: showFreezeForm, setFalse: hideFreezeForm }] = useBoolean(false);

    const setFreezeSettings = (freezeSettings: FreezeSettings) => {
        new FeatureFlightService().setFreeze(freezeSettings).then(() => {
            hideFreezeForm();
            if (ffv2Context.refreshFreeze) ffv2Context.refreshFreeze();
        });
    };

    return (
        <>
            <EditFlightForm
                isOpen={isEditFlightModalOpen}
                onDismiss={hideEditFlightModal}
                flight={selectedFlight}
                updateFlight={props.updateFlight}
            />
            <FreezeForm isOpen={isFreezeFormOpen} onDismiss={hideFreezeForm} setFreezeSettings={setFreezeSettings} />
            <Stack tokens={gapStackTokensSmall}>
                <CommandBar
                    items={prepareCommandBarItems()}
                    farItems={prepareCommandBarFarItems()}
                    ariaLabel="Actions"
                    primaryGroupAriaLabel="Flight actions"
                    farItemsGroupAriaLabel="Filter actions"
                />
                {isFilterOptionsOpen && (
                    <FlightFiltersBar
                        flightFilters={props.flightFilters}
                        flightStages={props.flightStages}
                        flightStatusFilter={props.flightStatusFilter}
                        handleFilterChange={props.handleFilterChange}
                        clearFilters={props.clearFilters}
                        selectedFlight={selectedFlight}
                        otherUsers={props.otherUsers}
                    />
                )}
            </Stack>
        </>
    );

    function prepareCommandBarItems(): ICommandBarItemProps[] {
        return [
            {
                key: 'freezeFFv2',
                text: 'Freeze FFv2',
                ariaLabel: 'Freeze FFv2',
                iconProps: { iconName: 'CircleStopSolid' },
                onClick: showFreezeForm,
                buttonStyles: {
                    icon: { color: theme.palette.red },
                    iconHovered: { color: theme.palette.redDark },
                    root: { color: theme.palette.red }
                }
            },
            {
                key: 'editFlight',
                text: 'Edit',
                ariaLabel: 'Edit',
                iconProps: { iconName: 'edit' },
                onClick: showEditFlightModal,
                disabled: false
            },
            {
                key: 'reroll',
                text: 'Reroll',
                ariaLabel: 'Reroll',
                iconProps: { iconName: 'redo' },
                onClick: () => console.log('reroll flight'),
                disabled: true || selectedFlight === undefined
            },
            {
                key: 'rollback',
                text: 'Rollback',
                ariaLabel: 'Rollback',
                iconProps: { iconName: 'undo' },
                onClick: () => console.log('rollback flight'),
                disabled: true || selectedFlight === undefined
            }
        ];
    }

    function prepareCommandBarFarItems(): ICommandBarItemProps[] {
        return [
            {
                key: 'refresh',
                iconProps: { iconName: 'Refresh' },
                title: 'Refresh data',
                onClick: props.handleRefreshData
            },
            {
                key: 'filter',
                iconProps: { iconName: isFilterOptionsOpen ? 'FilterSolid' : 'Filter' },
                title: isFilterOptionsOpen ? 'Hide filters' : 'Show filters',
                onClick: () => (isFilterOptionsOpen ? hideFilterOptions() : showFilterOptions())
            }
        ];
    }
};

export default AdminFlightsCommandBar;
