import {
    Panel,
    PrimaryButton,
    Text,
    Stack,
    DefaultButton,
    Spinner,
    MessageBar,
    MessageBarType,
    ActivityItem,
    IActivityItemProps,
    Icon,
    Persona,
    PersonaSize
} from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import FeatureFlightService from '../../../services/featureFlight.service';
import MsGraphService from '../../../services/msGraph.service';
import { getUserInfo } from '../../../utils/userUtility';
import FFv2Context from '../contexts/FFv2Context';
import UserContext from '../contexts/UserContext';
import { gapStackTokensMedium, gapStackTokensSmall } from '../styles/FFv2Style';
import {
    FlightRequest,
    FlightRequestApprover,
    FlightRequestApproverType,
    FlightRequestStatus,
    FlightRequestUpdateType
} from '../types/flightRequest.types';
import { ApprovalStatus, ProcessedFlightRollout, UserInfo } from '../types/Types';
import { capitalizeFirstLetter } from '../utilities/FFv2Utils';

type FlightRequestPanelProps = {
    setFlightRequest: (request: FlightRequest | undefined) => void;
    fetchFlight: () => void;
    flight: ProcessedFlightRollout;
    flightRequest?: FlightRequest;
};

const terminateStatuses = [FlightRequestStatus.Rejected, FlightRequestStatus.Cancelled, FlightRequestStatus.Completed];

/**
 * A Panel of flight request details.
 *
 * @param {FlightRequestPanelProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const FlightRequestPanel: React.FunctionComponent<FlightRequestPanelProps> = (props) => {
    const { setFlightRequest, flightRequest, flight } = props;
    const userContext = useContext(UserContext);
    const operationStatus = useContext(FFv2Context).operationStatus;
    const currentUser = useConst(getUserInfo());

    const [updateResult, setUpdateResult] = useState<Error | 'loading' | undefined>();
    const [managerAndSkip, setManagerAndSkip] = useState<
        | {
              manager: UserInfo;
              skip: UserInfo;
          }
        | undefined
    >();

    const isManager = managerAndSkip?.manager.id === currentUser.id;
    const isSkip = managerAndSkip?.skip.id === currentUser.id;
    const isRollbackDisabled = operationStatus?.cloudRings.includes(flight.state.environment + '_' + flight.state.ring);

    const userIdentities = getUserIdentities();

    const activityItems = getUpdateActivityItems();

    useEffect(() => {
        setUpdateResult(undefined);
        if (
            flightRequest &&
            flightRequest.creator.id &&
            flightRequest.approvals.some((approval) =>
                approval.approvers.some((approver) => approver.type === FlightRequestApproverType.Manager)
            )
        ) {
            fetchManagerAndSkip();
        }
    }, [flightRequest]);

    const approve = () => {
        update(FlightRequestUpdateType.Approve);
    };

    const reject = () => {
        update(FlightRequestUpdateType.Reject);
    };

    const cancel = () => {
        update(FlightRequestUpdateType.Cancel);
    };

    const complete = () => {
        update(FlightRequestUpdateType.Complete);
    };

    const update = (op: FlightRequestUpdateType) => {
        if (!flightRequest) return;

        const updateFlightRequestForm = {
            type: op,
            userIdentities: userIdentities
        };

        updateFlightRequestForm.type = op;

        setUpdateResult('loading');
        new FeatureFlightService()
            .updateFlightRequest(flightRequest.id, updateFlightRequestForm)
            .then((response) => {
                setFlightRequest(response);
                setUpdateResult(undefined);
                if (response.status === FlightRequestStatus.Completed) {
                    props.fetchFlight();
                }
            })
            .catch((error) => {
                setUpdateResult(error);
            });
    };

    // TODO: Allow comments to be added to the request
    return (
        <div>
            {flightRequest && (
                <div>
                    <Panel
                        headerText={`${capitalizeFirstLetter(flightRequest.type)} Request (${capitalizeFirstLetter(flightRequest.status)})`}
                        isOpen={flightRequest !== undefined}
                        onDismiss={() => setFlightRequest(undefined)}
                        closeButtonAriaLabel="Close"
                        styles={{ main: { minWidth: 400 } }}
                    >
                        <Stack tokens={gapStackTokensMedium}>
                            <Text>Requestor: {flightRequest.creator.displayName ?? flightRequest.creator.principalName.split('@')[0]}</Text>
                            <Text>Options:</Text>
                            <ul>
                                {Object.entries(flightRequest.options).map(([option, value]) => (
                                    <li key={option}>
                                        <Text>
                                            {option}: {value}
                                        </Text>
                                    </li>
                                ))}
                            </ul>
                            {flightRequest.comment && <Text>Requestor Comment: {flightRequest.comment}</Text>}
                            {flightRequest.approvals.map((approval, index) => (
                                <Stack key={`approval${index}`} tokens={gapStackTokensMedium}>
                                    <Text>Approval Group {index + 1}:</Text>
                                    {approval.approvers.map((approver, approverIndex) => (
                                        <Stack
                                            horizontal
                                            tokens={gapStackTokensMedium}
                                            verticalAlign="center"
                                            key={`approval${index}-approver${approverIndex}`}
                                        >
                                            <Icon
                                                iconName={
                                                    approver.status === ApprovalStatus.Pending
                                                        ? 'StatusCircleRing'
                                                        : approver.status === ApprovalStatus.Approved
                                                          ? 'StatusCircleCheckmark'
                                                          : 'StatusCircleErrorX'
                                                }
                                                style={{
                                                    fontSize: 20,
                                                    alignItems: 'center'
                                                }}
                                            />
                                            <Persona
                                                text={getApproverName(approver)}
                                                secondaryText={approver.type}
                                                size={PersonaSize.size40}
                                            />
                                        </Stack>
                                    ))}
                                </Stack>
                            ))}
                            <Text>History:</Text>
                            <Stack>
                                {activityItems.map((item: { key: string | number }) => (
                                    <ActivityItem {...(item as IActivityItemProps)} key={item.key} />
                                ))}
                            </Stack>
                            {updateResult !== 'loading' && (
                                <Stack horizontal tokens={gapStackTokensSmall}>
                                    {!terminateStatuses.includes(flightRequest.status) && userIdentities.length > 0 && (
                                        <>
                                            <PrimaryButton
                                                text="Approve"
                                                onClick={approve}
                                                title={
                                                    isRollbackDisabled
                                                        ? 'Cannot approve and complete rollback from R3+ during freeze'
                                                        : `You may approve as: ${userIdentities.map((identity) => `${identity}`).join(', ')}
                                                      `
                                                }
                                                styles={buttonStyles}
                                                disabled={isRollbackDisabled}
                                            />
                                            <PrimaryButton text="Reject" onClick={reject} styles={buttonStyles} />
                                        </>
                                    )}

                                    {flightRequest.status === FlightRequestStatus.Approved && (
                                        <PrimaryButton
                                            text="Complete"
                                            title={
                                                isRollbackDisabled
                                                    ? 'Cannot complete rollback from R3+ during freeze'
                                                    : 'Complete the request'
                                            }
                                            disabled={
                                                (flightRequest.creator.principalName !== currentUser.principalName &&
                                                    !isManager &&
                                                    !isSkip &&
                                                    flight.createdBy.principalName !== currentUser.principalName) ||
                                                isRollbackDisabled
                                            }
                                            onClick={complete}
                                            styles={buttonStyles}
                                        />
                                    )}

                                    {!terminateStatuses.includes(flightRequest.status) && flightRequest.creator.id === currentUser.id && (
                                        <DefaultButton
                                            text="Cancel"
                                            disabled={
                                                flightRequest.creator.principalName !== currentUser.principalName &&
                                                !isManager &&
                                                !isSkip &&
                                                flight.createdBy.id !== currentUser.id
                                            }
                                            onClick={cancel}
                                            styles={buttonStyles}
                                            split
                                        />
                                    )}
                                </Stack>
                            )}
                            {updateResult === 'loading' && <Spinner label="Updating..." />}
                            {updateResult instanceof Error && (
                                <MessageBar
                                    messageBarType={MessageBarType.error}
                                    onDismiss={() => {
                                        setUpdateResult(undefined);
                                    }}
                                >
                                    {updateResult.message}
                                </MessageBar>
                            )}
                        </Stack>
                    </Panel>
                </div>
            )}
        </div>
    );

    function fetchManagerAndSkip() {
        const msGraphService = new MsGraphService();
        msGraphService
            .getManager(flightRequest?.creator.id)
            .then((manager) => {
                msGraphService.getManager(manager.id).then((skip) => {
                    setManagerAndSkip({
                        manager: {
                            id: manager.id,
                            displayName: manager.displayName,
                            principalName: manager.userPrincipalName
                        } as UserInfo,
                        skip: {
                            id: skip.id,
                            displayName: skip.displayName,
                            principalName: skip.userPrincipalName
                        } as UserInfo
                    });
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function getUserIdentities() {
        const result: string[] = [];
        flightRequest?.approvals
            .filter((approval) => approval.approvers.every((approver) => approver.status === ApprovalStatus.Pending))
            .forEach((approval) => {
                approval.approvers.forEach((approver) => {
                    if (approver.groupName && userContext.securityGroups.some((group) => group.displayName === approver.groupName)) {
                        result.push(approver.groupName);
                    }
                    if (approver.userPrincipalName === currentUser.principalName) {
                        result.push(approver.userPrincipalName);
                    }
                    if (approver.type === FlightRequestApproverType.Manager && isManager) {
                        result.push(FlightRequestApproverType.Manager);
                    }
                    if (approver.type === FlightRequestApproverType.Skip && isSkip) {
                        result.push(FlightRequestApproverType.Skip);
                    }
                });
            });

        return result;
    }

    function getUpdateActivityItems() {
        if (!flightRequest) return [];
        return flightRequest?.updates.map((item) => {
            let iconName = 'Add';

            if (item.type === FlightRequestUpdateType.Approve) {
                iconName = 'CheckMark';
            }
            if (item.type === FlightRequestUpdateType.Reject) {
                iconName = 'Cancel';
            }
            if (item.type === FlightRequestUpdateType.Cancel) {
                iconName = 'Delete';
            }
            if (item.type === FlightRequestUpdateType.Complete) {
                iconName = 'Completed';
            }

            return {
                key: item.timestamp,
                activityDescription: `${capitalizeFirstLetter(item.type)}${item.type.endsWith('e') ? 'd' : 'ed'} ${item.user ? `by ${item.user.displayName ?? item.user.principalName.split('@')[0]}` : ''}${item.userIdentities ? ` as ${item.userIdentities.join(', ')}` : ''}`,
                activityIcon: <Icon iconName={iconName} />,
                comments: item.comment,
                timeStamp: new Date(item.timestamp).toLocaleString()
            };
        });
    }

    function getApproverName(approver: FlightRequestApprover) {
        if (approver.groupName) return approver.groupName;
        if (approver.userPrincipalName) return approver.userPrincipalName.split('@')[0];
        if (approver.type === FlightRequestApproverType.Manager) {
            return managerAndSkip?.manager.displayName ?? managerAndSkip?.manager.principalName ?? 'Manager';
        }
        if (approver.type === FlightRequestApproverType.Skip) {
            return managerAndSkip?.skip.displayName ?? managerAndSkip?.skip.principalName ?? 'Skip';
        }
        return approver.type;
    }
};

const buttonStyles = {
    root: {
        width: '100px'
    }
};

export default FlightRequestPanel;
