import { Checkbox, DefaultButton, MessageBar, MessageBarType, PrimaryButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';

import { appInsightsClient } from '../../../utils/appInsightsUtility';
import {
    desktopTCNS,
    desktopVersion,
    experience,
    experienceBuild,
    skypeAClientPlatform,
    userOverrideCategory,
    webRWC
} from '../configs/defaults';
import { gapStackTokensMedium } from '../styles/FFv2Style';
import { formButtonStyles, formPrimaryButtonStyles, stackItemStyles } from '../styles/StartFlightFormStyle';
import { EditFlightFormData, StartFlightFormData } from '../types/Types';
import { areObjectsEqual, flattenJSON, isValidClientVersion, isValidRolloutName } from '../utilities/FFv2Utils';

type FormButtonsProps = {
    formData: React.MutableRefObject<StartFlightFormData | EditFlightFormData>;
    audience: string[] | string;
    client: string;
    handleReset: () => void;
    handleSubmit: (message: string) => void;
    defaultValue?: EditFlightFormData;
    areaPath: string;
};

/**
 * Renders the primary action buttons for the form.
 *
 * @param props - The props containing the form data to submit.
 * @returns The JSX element representing the primary action buttons for the form.
 */
const FormButtons: React.FC<FormButtonsProps> = (props) => {
    // ========================= State =========================
    const formData = props.formData.current;
    const audience = props.audience;
    const areaPath = props.areaPath;
    const isStartFlightForm = props.defaultValue === undefined;

    const [isAcknowledgeChecked, setIsAcknowledgeChecked] = useState<boolean>(false);
    const [validateErrorMsg, setValidateErrorMsg] = useState<string>('');

    // ========================= Event handlers =========================
    const handleSubmit = () => {
        appInsightsClient.logEvent({ name: 'FFV2:StartFlight:FormSubmit' });

        const message = getValidateErrorMessage();
        setValidateErrorMsg(message);
        props.handleSubmit(message);
    };

    const handleReset = () => {
        appInsightsClient.logEvent({ name: 'FFV2:StartFlight:FormClear' });

        setValidateErrorMsg('');
        setIsAcknowledgeChecked(false);
        props.handleReset();
    };

    const getValidateErrorMessage = (): string => {
        if (!isStartFlightForm && areObjectsEqual(formData, props.defaultValue)) {
            return 'No changes made';
        }

        const message = [] as string[];
        if ('flightType' in formData && !Object.keys(formData.flightType).length) {
            message.push('flight type');
        }
        if ('boardingPassId' in formData && formData.boardingPassId === 0) {
            message.push('ADO work item');
        }
        if (!isValidRolloutName(formData.rolloutName)) {
            message.push('rollout name');
        }
        if ('client' in formData && !formData.client) {
            message.push('client');
        }
        if ('client' in formData && formData.client === webRWC && (formData.filters[experience].value as string[]).length === 0) {
            message.push('experience');
        }
        if ('category' in formData) {
            if (!formData.category) {
                // Category must exist
                message.push('category');
            } else if (formData.category === userOverrideCategory) {
                // Validations specific to the user override category
                if (formData.audience !== 'enterprise') {
                    message.push('audience');
                }

                if (!formData.userFilters || !Object.keys(formData.userFilters).length) {
                    message.push('user filters');
                }
            }
        }
        if (!audience.length) {
            message.push('audience');
        }
        if (!areaPath?.length) {
            message.push('area path');
        }
        if (!formData.filters[skypeAClientPlatform].value.length) {
            message.push('platforms');
        }
        const client = 'client' in formData ? formData.client : props.client;
        if (
            (client === webRWC &&
                (!formData.filters[experienceBuild] || !isValidClientVersion(client, formData.filters[experienceBuild].value as string))) ||
            (client === desktopTCNS &&
                (!formData.filters[desktopVersion] || !isValidClientVersion(client, formData.filters[desktopVersion].value as string)))
        ) {
            message.push('minimum build number');
        }
        if (
            Object.entries(formData.filters).filter(
                ([filterName, filterValue]) =>
                    (!filterName || !filterValue || !filterValue.value || !filterValue.operation) &&
                    (client === webRWC ? filterName !== experienceBuild : filterName !== desktopVersion)
            ).length
        ) {
            message.push('filters');
        }
        if (
            !Object.keys(formData.featureFlags).length ||
            Object.values(flattenJSON(formData.featureFlags)).some((value) => value === undefined)
        ) {
            message.push('flags');
        }
        if (
            'controlConfigs' in formData &&
            (JSON.stringify(Object.keys(flattenJSON(formData.featureFlags))) !==
                JSON.stringify(Object.keys(flattenJSON(formData.controlConfigs))) ||
                Object.values(flattenJSON(formData.controlConfigs)).some((value) => value === undefined))
        ) {
            message.push('control configs');
        }
        if (message.length) return 'Invalid fields: ' + message.join(', ');
        else return '';
    };

    const handleAcknowledgeChecked = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
        setIsAcknowledgeChecked(isChecked ?? false);
        appInsightsClient.logEvent({
            name: 'FFV2:StartFlight:AcknowledgeCheckbox',
            properties: { isChecked: isChecked }
        });
    };

    // TODO: View to confirm changes
    return (
        <>
            <Checkbox
                label={
                    isStartFlightForm
                        ? 'I acknowledge and understand that the flags and values provided will automatically progress across all rings (with manager approval after R0) unless a flight blocker is raised.'
                        : 'I have carefully reviewed all my changes.'
                }
                checked={isAcknowledgeChecked}
                required
                onChange={handleAcknowledgeChecked}
            />
            <Stack>
                <Stack.Item>
                    <Stack horizontal tokens={gapStackTokensMedium}>
                        <Stack.Item styles={stackItemStyles}>
                            <PrimaryButton
                                text="Validate + Take Off"
                                iconProps={{ iconName: 'Airplane' }}
                                onClick={handleSubmit}
                                styles={formPrimaryButtonStyles}
                                allowDisabledFocus
                                disabled={!isAcknowledgeChecked}
                            />
                        </Stack.Item>
                        <Stack.Item styles={stackItemStyles}>
                            <DefaultButton
                                text="Reset"
                                iconProps={{ iconName: 'Clear' }}
                                onClick={handleReset}
                                styles={formButtonStyles}
                                allowDisabledFocus
                            />
                        </Stack.Item>
                        <Stack.Item align="center">
                            {isAcknowledgeChecked && validateErrorMsg.length > 0 && (
                                <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
                                    {validateErrorMsg}.
                                </MessageBar>
                            )}
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    );
};

export default FormButtons;
