import { ComboBox, IComboBox, IComboBoxOption, IComboBoxOptionStyles, DirectionalHint, Stack, IComboBoxProps } from '@fluentui/react';
import { DropdownMenuItemType } from '@fluentui/react/lib/Dropdown';
import React, { useCallback, useEffect } from 'react';

import FeatureFlagService from '../../../services/featureFlight.service';
import { AreaPathObject } from '../types/Types';

type AreaPathDropdownProps = {
    defaultValue?: string;
    update: (value: string) => void;
    onAreaPathChange: (areaPath: string) => void;
};

/**
 * Renders a Dropdown to select area path options.
 *
 * @param props - From start flight form.
 * @returns The JSX element representing the experience combo box.
 */
const AreaPathDropdown: React.FC<AreaPathDropdownProps> = (props) => {
    const { update } = props;
    const defaultValue = props.defaultValue;
    const [areaPaths, setAreaPaths] = React.useState<string[]>([]);
    const [selectedKey, setSelectedKey] = React.useState<string>(defaultValue ?? '');

    const getAreaPaths = async () => {
        const res = await new FeatureFlagService().getAllAreaPaths();
        setAreaPaths(getPathArrayFromResponse(res));
    };

    const onItemClick = useCallback((event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (option) {
            setSelectedKey(option.key.toString());
            update(option.key.toString());
        }
    }, []);

    const getPathArrayFromResponse = (response: { count: number; value: AreaPathObject[] }): string[] => {
        const current = response.value;
        const collectPaths: string[] = [];
        const traverse = (curr: AreaPathObject[]) => {
            for (let i = 0; curr.at(i); i++) {
                if (curr[i].structureType === 'iteration') {
                    continue;
                }
                if (curr[i].hasChildren) {
                    traverse(curr[i].children);
                } else {
                    const substringToRemove = '\\Area';
                    let path = curr[i].path.substring(1);
                    path = path.replace(substringToRemove, '');
                    collectPaths.push(path);
                }
            }
        };
        traverse(current);
        return collectPaths;
    };
    const options: IComboBoxOption[] = areaPaths.reduce((collect, key, index) => {
        collect.push({ key, text: key });
        if (index < areaPaths.length - 1) {
            collect.push({ key: `divider_${index}`, text: '-', itemType: DropdownMenuItemType.Divider });
        }
        return collect;
    }, [] as IComboBoxOption[]);
    useEffect(() => {
        getAreaPaths();
    }, []);
    useEffect(() => {
        setSelectedKey(defaultValue ?? '');
        update(selectedKey);
    }, [defaultValue, update]);
    const comboBoxProps: Partial<IComboBoxProps> = {
        calloutProps: {
            directionalHintFixed: true,
            directionalHint: DirectionalHint.bottomLeftEdge
        }
    };
    const comboBoxStyles: Partial<IComboBoxOptionStyles> = {
        root: { maxWidth: 400 },
        optionText: { overflow: 'visible', whiteSpace: 'normal' }
    };
    useEffect(() => {
        props.onAreaPathChange(selectedKey);
    }, [selectedKey]);
    return (
        <Stack tokens={{ childrenGap: 20 }}>
            <ComboBox
                placeholder="Select a path"
                options={options}
                selectedKey={selectedKey}
                onItemClick={onItemClick}
                styles={comboBoxStyles}
                {...comboBoxProps}
            />
        </Stack>
    );
};

export default AreaPathDropdown;
