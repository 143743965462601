import { DatePicker, IComboBoxStyles, IDatePickerProps, ITimePickerProps, Stack, TimePicker } from '@fluentui/react';
import { set } from 'date-fns';
import React, { useEffect, useState } from 'react';

// Based on DateAndTimePicker.tsx from src/pages/RecentChangesReport/FilterForm/DateAndTimePicker.tsx

const stackTokens = { childrenGap: 3 };
const comboBoxStyles: Partial<IComboBoxStyles> = {
    root: { minWidth: 100, maxWidth: 100, maxHeight: 200 },
    callout: { minWidth: 100 }
};

type DateAndTimePickerProps = {
    datePickerProps: IDatePickerProps;
    timePickerProps: ITimePickerProps;
    onChange: (date: Date | undefined) => void;
    reset: boolean;
    defaultDate?: Date;
};

/**
 * A Date and Time Picker component.
 *
 * @param props The props for the component.
 * @returns The component to display the date and time picker.
 */
export const DateAndTimePicker: React.FC<DateAndTimePickerProps> = (props) => {
    const { datePickerProps, timePickerProps } = props;

    const [datePickerDate, setDatePickerDate] = useState<Date | undefined>(props.defaultDate);
    const [timePickerDate, setTimePickerDate] = useState<Date | undefined>(props.defaultDate);

    useEffect(() => {
        setDatePickerDate(props.defaultDate);
        setTimePickerDate(props.defaultDate);
    }, [props.reset, props.defaultDate]);

    return (
        <Stack horizontal tokens={stackTokens}>
            <DatePicker
                {...datePickerProps}
                isRequired={timePickerDate !== undefined}
                onSelectDate={(selectedDate) => {
                    if (!selectedDate) return;
                    setDatePickerDate(selectedDate);
                    // set time to value from timepicker date
                    const newDate = timePickerDate
                        ? set(selectedDate, {
                              hours: timePickerDate.getHours(),
                              minutes: timePickerDate.getMinutes(),
                              seconds: 0,
                              milliseconds: 0
                          })
                        : undefined;
                    props.onChange(newDate);
                }}
                value={datePickerDate}
            />
            <TimePicker
                {...timePickerProps}
                onValidateUserInput={(input) => {
                    if (input === '') {
                        return datePickerDate !== undefined ? 'Must select time' : '';
                    }
                    const time = input.split(':');
                    if (time.length === 2 && !isNaN(+time[0]) && !isNaN(+time[1])) {
                        return '';
                    }
                    return 'Invalid time format';
                }}
                onChange={(_ev, date: Date) => {
                    setTimePickerDate(date);
                    // set date to value from date picker date
                    const newDate =
                        datePickerDate !== undefined
                            ? set(datePickerDate, {
                                  hours: date.getHours(),
                                  minutes: date.getMinutes(),
                                  seconds: 0,
                                  milliseconds: 0
                              })
                            : undefined;
                    props.onChange(newDate);
                }}
                value={timePickerDate}
                dateAnchor={datePickerDate}
                styles={comboBoxStyles}
            />
        </Stack>
    );
};
